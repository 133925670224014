<template>
    <BaseModal
        v-if="displayModal"
        modal-title="Login Expired"
        description-for-screen-readers="Important message about expiry of login details"
        @close="closeModal"
    >
        <template #body>
            <p>
                Your session has expired for the {{ siteName }}.
            </p>
            <p>
                Click the login button below to return to the site.
                You will see more relevant and personalised information when logged in.
            </p>
        </template>
        <template #footer>
            <div class="u-align-right u-xxs-align-center">
                <GenericButton
                    flavour="secondary-red"
                    class="a-button--full-width-xxs"
                    @click="loginUser"
                >
                    <template #button-text>
                        Login to {{ siteName }}
                    </template>
                </GenericButton>
                <GenericButton
                    class="u-xxs-mar-t-1 a-button--full-width-xxs"
                    @click="closeModal"
                >
                    <template #button-text>
                        Stay logged out
                    </template>
                </GenericButton>
            </div>
        </template>
    </BaseModal>
</template>

<script>
import { getJwt } from '@core-portal/api/identity.api';
import { cancelSiteAction, SITE_ACTIONS } from '@utilities/SiteActionHelper';

import BaseModal from '@objects/BaseModal.vue';
import GenericButton from '@atoms/buttons/GenericButton.vue';

export default {
    components: {
        BaseModal,
        GenericButton
    },
    props: {
        siteName: {
            type: String,
            required: true,
            default: 'Portal'
        }
    },
    data() {
        return {
            showModal: true
        };
    },
    computed: {
        displayModal() {
            return this.showModal;
        }
    },
    methods: {
        closeModal() {
            this.showModal = false;
            cancelSiteAction(SITE_ACTIONS.TOKEN_EXPIRED, false);
        },
        loginUser() {
            this.closeModal();
            getJwt();
        }
    }
};
</script>