import { SECONDARY_NAV_ROUTE_KEYS } from '@config/constants';

export default [
    {
        path: '/notices',
        component: () => import('@pages/notices/NoticeRedirect.vue'),
        name: 'notices.redirect',
        meta: {
            requiresAuth: true,
            title: 'Notices',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS
        }
    },
    {
        path: '/notices/:slug',
        component: () => import('@pages/notices/AllNotices.vue'),
        name: 'notices',
        meta: {
            requiresAuth: true,
            title: 'Notices',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS,
			
        }
    },
    {
        path: '/notices/item/:id/:slug',
        component: () => import('@pages/notices/NoticeTemplate.vue'),
        name: 'notice',
        meta: {
            title: 'Notice',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS,
            requiresAuth: true,
            subNav: true,
            subNavName: 'Notice'
        }
    }
];