import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';

import router from './router';
import EntryPoint from '@pages/EntryPoint.vue';
import HeaderApp from '@core-portal/components/organisms/HeaderApp.vue';
import MegaMenuApp from '@core-portal/components/organisms/menus/MegaMenuApp.vue';
import NotificationDrawer from '@organisms/notifications/NotificationDrawer.vue';
import '../Style/z-builds/master.css';

// any directives
import { ObserveVisibility } from 'vue-observe-visibility';

// initialises the Vue part of the page
window.__initPage = function (data) {
    // store
    const pinia = createPinia();
    pinia.use(createPersistedState({
        // https://prazdevs.github.io/pinia-plugin-persistedstate/
        key: id => `portal-${id}`,
        storage: sessionStorage
    }));

    /* eslint-disable vue/one-component-per-file */
    const headerApp = createApp(HeaderApp, { initialData: data.appHeader })
        .use(pinia)
        .use(router);
    headerApp.mount('#header-app');
     
    const notificationDrawer = createApp(NotificationDrawer)
        .use(pinia)
        .use(router);
    notificationDrawer.mount('#notification-drawer');
     
    const megaMenuApp = createApp(MegaMenuApp)
        .use(pinia)
        .use(router);
    megaMenuApp.mount('#org-js-mega-menu');

    const vueApp = createApp(EntryPoint, { initialData: data.app })
        .use(pinia)
        .use(router);
    /* eslint-enable vue/one-component-per-file */

    // be specific about additional plugins and directives
    vueApp.directive('observe-visibility', ObserveVisibility);

    // mount to DOM
    vueApp.mount('#index');
};
window.dispatchEvent(new CustomEvent('initpageready'));