import { useTokenStore } from '@core-portal/stores/token.module';
import { removeSiteActionModalSessionFlags } from '@utilities/SiteActionHelper';
import { useNotificationsStore } from '@stores/notifications.module';
import { useJobsStore } from '@stores/jobs.module';
import { useNoticeboardStore } from '@stores/noticeboard.module';
import { useWidgetsStore } from '@stores/widgets.module';
import { useMenuStore } from '@core-portal/stores/menu.module';
import { useEventsStore } from '@stores/events.module';
import { useAppStore } from '@stores/app.module';
import { useDspStore } from '../../stores/dsp.module';
import { useNewsStore } from '../../stores/news.module';
import { usePreferencesStore } from '../../stores/preferences.module';
import { useIdentityStore } from '@stores/identity.module';

/**
 * Clear any data that may be dependent on the user/user-type
 * and reset the state back to it's initial form
 */
export function clearAuthenticatedData() {

    // clear identity first
    const identityStore = useIdentityStore();
    const tokenStore = useTokenStore();
    tokenStore.reset();
    identityStore.reset(); 

    /**
     * Clear the Pinia stores
     */
    const notificationsStore = useNotificationsStore();
    notificationsStore.reset();

    const jobsStore = useJobsStore();
    jobsStore.reset();

    const noticeboardStore = useNoticeboardStore();
    noticeboardStore.reset();

    const widgetStore = useWidgetsStore();
    widgetStore.reset();
  
    const menuStore = useMenuStore();
    menuStore.$reset();
  
    const eventsStore = useEventsStore();
    eventsStore.reset();

    const appStore = useAppStore();
    appStore.reset();

    const dspStore = useDspStore();
    dspStore.reset();

    const newsStore = useNewsStore();
    newsStore.reset();

    const preferencesStore = usePreferencesStore();
    preferencesStore.reset();

    /**
     * Clear any browser session data
     */
    removeSiteActionModalSessionFlags();
}