const PcAvailabilityPage = () => import('@pages/pc-availability/PcAvailabilityPage.vue');

export default [
    {
        path: '/pcavailability',
        component: PcAvailabilityPage,
        name: 'pc.availability',
        meta: {
            requiresAuth: true,
            title: 'PC Availability'
        }
    }
];