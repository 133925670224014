import { getSiteModeFromPath } from '@utilities/SiteHelper.js';
import { PREFERENCE_KEYS, REQUEST_STATUS, SITEMODE, SITESTYLE } from '@config/constants';
import { getOsColourPreference } from '@core-portal/scripts/colourTheme.helper';
import { updatePreference } from '@api/preferences';
import { defineStore } from 'pinia';

const osTheme = getOsColourPreference(); // Set the color preference from the OS

export const useIdentityStore = defineStore('identity', {
    state: () => ({
        profile: {
            designTheme: null,
            isAuthenticated: false,
            isStaff: false,
            isStudent: false,
            isImpersonating: false,
            roles: [],
            siteActions: {
                tokenExpired: false
            },
            siteStyle: SITESTYLE.LANCASTER
        },
        status: null
    }),

    persist: true, // Enables persistence for the store

    getters: {
        /**
         * Is the user currently authenticated
         * @param {object} state app state instance
         * @returns {boolean} authenticated status
         */
        authenticated: (state) => state.profile.isAuthenticated,
        /**
         * Is the user a staff member
         * @param {object} state app state instance
         * @returns {boolean} if user is in the staff role
         */
        staff: (state) => state.profile.isStaff,
        /**
         * Is the user a student
         * @param {object} state app state instance
         * @returns {boolean} if user is in the student role
         */
        student: (state) => state.profile.isStudent,
        /**
         * Is the user both a staff member and also a student
         * @param {object} state app state instance
         * @returns {boolean} if user is in both the staff and student role
         */
        staffStudent: (state) => state.profile.isStaff && state.profile.isStudent,
        /**
         * Is the user currently being impersonated?
         * @param {object} state app state instance
         * @returns {boolean} if user is currently impersonating another user
         */
        impersonating: (state) => state.profile.isImpersonating,
        /**
         * Get the current site mode based on the user profile
         * @param {object} state app state instance
         * @returns {string} current site mode
         */
        siteMode: (state) => {
            const profile = state.profile;

            if (!profile || !profile.isAuthenticated) {
                return getSiteModeFromPath();
            } else if (profile.isStaff && profile.isStudent) {
                return SITEMODE.BOTH;
            } else if (profile.isStaff) {
                return SITEMODE.INTRANET;
            } else {
                return SITEMODE.PORTAL;
            }
        },
        /**
         * Is the user a PGA?
         * @param {object} state app state instancete
         * @returns {boolean} is the user classed as PGA
         */
        pga: (state) => state.profile.roles.includes('pga'),
        /**
         * Is the user only a PGA and not staff or student?
         * @param {object} state app state instance
         * @returns {boolean} is the user classed as only a PGA and not staff or student
         */
        pgaOnly: (state) =>
            state.profile.roles.includes('pga') &&
            !state.profile.isStaff &&
            !state.profile.isStudent,
        /**
         * Is the user a Distance Learner?
         * @param {object} state app state instance
         * @returns {boolean} is the user classed as distance learner
         */
        distance: (state) => state.profile.roles.includes('distance'),
        /**
         * Retrieve site action data by key
         * @param {object} state app state instance
         * @returns {boolean} The site action flag (e.g. true/false/null)
         */
        getSiteAction: (state) => (key) => state.profile.siteActions[key],
        /**
         * Get the current design theme from profile or default to OS theme
         * @param {object} state app state instance
         * @returns {string} user's site design theme preference
         */
        designTheme: (state) => state.profile?.designTheme ?? osTheme
    },

    actions: {
        /**
         * Reset the state
         */
        reset() {
            this.$reset();
        },
        /**
         * Successfully fetched the profile object
         * @param {object} profile user profiule data
         */
        setProfile(profile) {
            this.profile = profile;
            this.status = REQUEST_STATUS.SUCCESS;
        },
        /**
         * Add flag for any site action key to the state
         * @param {object} value site action data model
         * @param {string} value.key The site action key
         * @param {object} value.flag The site action flag (true/false/null)
         */
        setSiteAction({ key, flag }) {
            this.profile.siteActions[key] = flag;
        },
        /**
         * Set the design theme
         * @param {string} theme design theme name
         */
        setDesignTheme(theme) {
            this.profile.designTheme = theme;
        },
        /**
         * Update the design theme and save the preference if authenticated
         * @param {string} theme design theme name
         */
        updateDesignTheme(theme) {
            this.setDesignTheme(theme);
            if (this.authenticated) { //save in the database - pass in `null` to reset
                updatePreference(PREFERENCE_KEYS.DESIGN_THEME, theme)
                    .catch(() => {
                        // Handle failure silently
                    });
            }
        }
    }
});
