import { defineStore } from 'pinia';
import { toTitleCase } from '@utilities/StringHelper';

export const useJobsStore = defineStore('jobs', {
    state: () => ({
        jobData: {}
    }),
    persist: true,
    getters: {
        getData: (state) => {
            return (slug) => {
                return state.jobData[slug] ||
                {
                    serviceStatus: [],
                    title: `${toTitleCase(slug)} Jobs`,
                    externalLink: null,
                    items: [],
                    categories: [],
                    locations: [],
                    countries: []
                };
            };
        }
    },

    actions: {
        reset() {
            this.$reset();
        },
        /**
         * Add data for a jobs to the state
         * @param {object} value jobs list data model
         * @param {string} value.slug the slug for the job type
         * @param {object[]} value.jobs the jobs data
         */
        setData(value) {
            this.jobData[value.slug] = value.jobs;

        },
    }

});