<template>
    <div
        id="user-menu"
        class="org-js-user-panel"
    >
        <ul
            class="e-no-bullet"
            role="menu"
        >
            <li
                v-if="profile.college"
                role="menuitem"
                class="a-navigation-link"
            >
                <a
                    id="my-college-link"
                    href="/ask/my-college"
                >
                    My College
                </a>
            </li>
            <li
                role="menuitem"
                class="a-navigation-link"
            >
                <router-link
                    id="my-profile-link"
                    :to="myProfileRoute"
                >
                    <span
                        role="link"
                        @click="navigate"
                        @keypress.enter="navigate"
                    >
                        My Profile
                    </span>
                </router-link>
            </li>
            <li
                role="menuitem"
                class="a-navigation-link"
            >
                <router-link
                    id="my-settings-link"
                    :to="mySettingsRoute"
                >
                    <span
                        role="link"
                        @click="navigate"
                        @keypress.enter="navigate"
                    >
                        My Settings
                    </span>
                </router-link>
            </li>
            <li
                role="menuitem"
                class="a-navigation-link"
            >
                <a
                    id="logout-button"
                    href=""
                    class="org-js-user-panel__link"
                    role="button"
                    @click.prevent="logout"
                >
                    Logout
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import { computed } from 'vue';
import { useIdentityStore } from '@stores/identity.module';
import { getBaseUri } from '@core-portal/scripts/siteBase.helper';
import { MYAREA_ROUTES } from '@routes/myArea';
import { clearAuthenticatedData } from '@core-portal/scripts/identity.helper';

export default {
    emits: [
        'navigate'
    ],
    setup() {
        const identityStore = useIdentityStore();
        const profile = computed(() => identityStore.profile);
        const userMenuLinks = computed(() => document.getElementById('user-menu').querySelectorAll('a'));

        return {
            profile,
            userMenuLinks
        };
    },
    data: () => ({
        myProfileRoute: { name: MYAREA_ROUTES.PROFILE },
        mySettingsRoute: { name: MYAREA_ROUTES.SETTINGS.HOME }
    }),

    methods: {
        logout() {
            clearAuthenticatedData();
            return window.location.assign(getBaseUri() + 'logout');
        },
        navigate() {
            this.$emit('navigate');
        }
    }
};
</script>