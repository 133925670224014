import { MPC_PATHS } from '@routes/module-programme-catalogue';
import { ASK_ROUTES, EVENT_NAME } from '@config/constants';

/**
 * Send an Analytics event
 * @param {object} eventData A collection of parameters that provide information about the event
 */
export function sendCustomEvent(eventData) {
    dataLayer.push(eventData);
}

/**
 * Add 'Programme : ' string to the label to help filter instances of programme links
 * @param {object} routerLink vue router link object
 * @param {string} url link to check if it's an instance of programme link
 * @param {string} label the label to be transformed when match occurs
 * @returns {string} updated label
 */
export function setProgrammeUrlLabel(routerLink, url, label) {

    // check if a router link is given
    if (routerLink && routerLink.startsWith(MPC_PATHS.PROGRAMMES)) {
        return `Programme : ${label}`;
    }

    // check if the raw URL is set
    if (url && (url.includes(`${MPC_PATHS.PROGRAMMES}/`) || url.endsWith(ASK_ROUTES.MY_PROGRAMME))) {
        return `Programme : ${label}`;
    }

    // return label unchanged
    return label;
}

/**
 * Track viewport data when browser window is resized or zoomed in/out
 */
export function setViewportTracking() {
    // track viewport data when window is resized or zoomed in
    const consent = JSON.parse(localStorage.getItem('consentMode'));
    // don't bother listening if there's no consent
    if (consent && consent.analytics_storage === 'granted') {
        // check if site laoded witin resized or zoomed window and log event if so
        if ((window.innerWidth < window.screen.availWidth) || (window.devicePixelRatio !== 1)) {
            sendCustomEvent({
                'event': EVENT_NAME.VIEWPORT_UPDATE,
                'viewport_resolution': `${window.innerWidth}x${window.innerHeight}`,
                'pixel_ratio': window.devicePixelRatio.toFixed(2),
                'event_action_source': 'window'
            });
        }
        let handlerTimeout = null;
        // listen for window resize events (captures zoom change too)
        window.addEventListener('resize', () => {
            // allow some time for widow resize action to finish
            clearTimeout(handlerTimeout);
            handlerTimeout = setTimeout(() => {
                sendCustomEvent({
                    'event': EVENT_NAME.VIEWPORT_UPDATE,
                    'viewport_resolution': `${window.innerWidth}x${window.innerHeight}`,
                    'pixel_ratio': window.devicePixelRatio.toFixed(2),
                    'event_action_source': 'window'
                });
            }, 3000);
        });
    }    
}