import { SECONDARY_NAV_ROUTE_KEYS } from '@config/constants';

const ArticleWrapper = () => import('@pages/ArticleWrapper.vue');
const EventTemplate = () => import('@pages/events/EventTemplate.vue');
const AllEvents = () => import('@pages/events/AllEvents.vue');
const EventRedirect = () => import('@pages/events/EventRedirect.vue');

export default [
    {
        path: '/events',
        component: EventRedirect,
        name: 'event',
        meta: {
            title: 'Events',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS
        }
    },
    {
        path: '/events/:slug',
        component: EventTemplate,
        name: 'event.item',
        meta: {
            title: 'Event Item',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS,
            subNav: true,
            subNavName: 'Event'
        }
    },
    {
        path: '/events/id/:eventId',
        component: EventTemplate,
        name: 'event.item.id',
        meta: {
            title: 'Event Item',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS,
            subNav: true,
            subNavName: 'Event'
        }
    },
    {
        path: '/events/channel',
        component: ArticleWrapper,
        name: 'allevents',
        // redirect to the news landing page if guid is not supplied
        redirect: '/news',
        children: [
            {
                path: ':guid',
                component: AllEvents,
                name: 'allevents.channel',
                meta: {
                    title: 'Events',
                    secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS
                }
            }
        ],
        meta: {
            title: 'All Events',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS			
        }
    }
];