import Fetch from '@api/fetch';

/**
 * Get a single layer of DSP content
 * @param {string} [section] section uri - defaults to null which would be the root section
 * @param {string} [subSection] sub section uri - defaults to null
 * @param {boolean} [clearCache] clear cache - defaults to false
 * @returns {Promise} Promise object represents api response
 */
export function getDspSections(section = null, subSection = null, clearCache = false) {
    // the base url
    let uri = '/api/services';

    // add any additional sections
    [section, subSection]
        .filter(x => x)
        .map(x => uri += `/${x}`);

    return Fetch.internal.get(uri, clearCache);
}

/**
 * Get the page content for a DSP contenet section
 * @param {string} section section uri
 * @param {string} subSection sub section uri
 * @param {string} content section ontent uri
 * @returns {Promise} Promise object represents api response
 */
export function getDspContent(section, subSection, content) {
    let uri = [section, subSection, content].join('/');

    return Fetch.internal.get(`/api/services/${uri}`);
}