import Fetch from '@api/fetch';

/**
 * Perform a search
 * @param {string} query search term
 * @param {number} start start index
 * @param {string} collection search collection name - defaults to null
 * @returns {Promise} Promise object represents api response
 */
export function getSearchItems(query, start, collection = null) {
    query = encodeURIComponent(query);
    let url = `api/search?q=${query}&index=${start}`;
    if (collection) {
        collection = encodeURIComponent(collection);
        url += `&subcollection=${collection}`;
    }
    return Fetch.internal.get(url);
}

/**
 * Fetch search query suggestions based on partial query input
 * @param {string} partialQuery partial query
 * @returns {Promise} Promise object represents api response
 */
export function getSearchSuggestions(partialQuery) {
    partialQuery = encodeURIComponent(partialQuery);
    let url = `api/search/suggestions?partialQuery=${partialQuery}`;
    return Fetch.internal.get(url);
}