import { initRouter } from './router';

// routes
import calendarRoutes from './routes/calendar';
import cmsRoutes from './routes/cms';
import condolencesRoutes from './routes/condolences';
import digitalServicesRoutes from './routes/digital-services';
import entryRoutes from './routes/entry';
import errorRoutes from './routes/error';
import eventRoutes from './routes/events';
import feedbackRoutes from './routes/feedback';
import jobsRoutes from './routes/jobs';
import myAreaRoutes from './routes/myArea';
import moduleProgrammeCatalogueRoutes from './routes/module-programme-catalogue';
import newsRoutes from './routes/news';
import newsPreviewRoutes from './routes/news-preview';
import noticeboardRoutes from './routes/noticeboard';
import noticesRoutes from './routes/notices';
import notificationsRoutes from './routes/notifications';
import pcAvailabilityRoutes from './routes/pcavailability';
import pgaRoutes from './routes/pga';
import registrationRoutes from './routes/registration';
import searchRoutes from './routes/search';
import travelRoutes from './routes/travel';

const routes = [
    ...calendarRoutes,
    ...cmsRoutes,
    ...condolencesRoutes,
    ...digitalServicesRoutes,
    ...entryRoutes,
    ...eventRoutes,
    ...feedbackRoutes,
    ...jobsRoutes,
    ...myAreaRoutes,
    ...moduleProgrammeCatalogueRoutes,
    ...newsRoutes,
    ...newsPreviewRoutes,
    ...noticeboardRoutes,
    ...noticesRoutes,
    ...notificationsRoutes,
    ...pcAvailabilityRoutes,
    ...pgaRoutes,
    ...registrationRoutes,
    ...searchRoutes,
    ...travelRoutes,
    // error routes *must* be last as it contains the 404 catch-all page
    ...errorRoutes
];

export default initRouter(routes);