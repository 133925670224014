<template>
    <button
        v-if="!authenticated"
        @click="login"
    >
        Login
    </button>
</template>

<script>
import { computed } from 'vue';
import { useIdentityStore } from '@stores/identity.module';
import { getJwt } from '@core-portal/api/identity.api';

export default {
    setup() {
        const identityStore = useIdentityStore();
        const profile = computed(() => identityStore.profile);
        const authenticated = computed(() => identityStore.profile.isAuthenticated);

        return {
            profile,
            authenticated
        };
    },

    methods: {
        login() {
            getJwt();
        },
    
    }
};
</script>
