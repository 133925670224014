<template>
    <BaseModal
        v-if="displayModal"
        :modal-title="question"
        :description-for-screen-readers="screenReaderDescription"
        hide-xclose-button
    >
        <template #body>
            <form>
                <div
                    v-if="beforeOptionsHtml"
                    v-html="beforeOptionsHtml"
                />
                <fieldset id="group1">
                    <div
                        v-for="option in options"
                        :key="option.id"
                        class="o-grid-radio-button-and-label"
                    >
                        <input
                            :id="option.id"
                            v-model="selectedOption"
                            type="radio"
                            :value="option.value"
                            name="group1"
                            tabindex="0"
                        >
                        <label :for="option.id">{{ option.text }}</label>
                    </div>
                </fieldset>
                <div
                    v-if="afterOptionsHtml"
                    v-html="afterOptionsHtml"
                />
                <div class="u-align-right u-xxs-align-center">
                    <GenericButton
                        flavour="red"
                        class="a-button--full-width-xxs"
                        :disabled="disabledSubmit"
                        @click="closeModal"
                    >
                        <template #button-text>
                            Submit
                        </template>
                    </GenericButton>
                </div>
            </form>
        </template>
    </BaseModal>
</template>
<script>
import { REQUEST_STATUS } from '@config/constants';
import { getCheckLocationContent, updateCheckLocationResponse } from '@api/siteAction';
import { cancelSiteAction, SITE_ACTIONS } from '@utilities/SiteActionHelper';

import GenericButton from '@atoms/buttons/GenericButton.vue';
import BaseModal from '@objects/BaseModal.vue';

export default {
    components: {
        GenericButton,
        BaseModal
    },
    props: {
        siteName: {
            type: String,
            required: true,
            default: 'Portal'
        }
    },
    data() {
        return {
            showModal: true,
            content: null,
            selectedOption: null,
            contentRequestStatus: null,
            updateRequestStatus: null
        };
    },
    computed: {
        displayModal() {
            // display the modal if we have a question and some options
            return this.showModal && this.question && this.options.length > 0;
        },
        question() {
            return this.content ? this.content.question : null;
        },
        screenReaderDescription() {
            return this.content ? this.content.screenReader : null;
        },
        beforeOptionsHtml() {
            return this.content ? this.content.beforeOptionsHtml : null;
        },
        afterOptionsHtml() {
            return this.content ? this.content.afterOptionsHtml : null;
        },
        options() {
            if (!this.content) {
                return [];
            }

            return this.content.items.map(item => {
                return {
                    id: 'option-' + item.value,
                    text: item.text,
                    value: item.value
                };
            });
        },
        disabledSubmit() {
            // can't submit if no option has been seleced or the submission is in progress
            return !this.selectedOption || this.updateRequestStatus === REQUEST_STATUS.REQUEST;
        }
    },
    beforeMount() {
        this.getContent();
    },
    methods: {
        async getContent() {
            try {
                this.contentRequestStatus = REQUEST_STATUS.REQUEST;
                this.content = await getCheckLocationContent();
                this.contentRequestStatus = REQUEST_STATUS.SUCCESS;
            } catch {
                this.contentRequestStatus = REQUEST_STATUS.FAILURE;
            }
        },
        async closeModal() {
            try {
                // attempt to submit the response
                this.updateRequestStatus = REQUEST_STATUS.REQUEST;
                await updateCheckLocationResponse(this.selectedOption);
                this.updateRequestStatus = REQUEST_STATUS.SUCCESS;
            } catch {
                this.updateRequestStatus = REQUEST_STATUS.FAILURE;
            } finally {
                // close the window even if it fails
                this.showModal = false;
                cancelSiteAction(SITE_ACTIONS.CHECK_LOCATION);
            }
        }
    }
};
</script>