import { getBaseUri } from '@core-portal/scripts/siteBase.helper';

export default class UrlHelper {

    /**
     * Method for getting the uri part from the base uri. This
     * happens when redirecting to 404 pages on feature branches etc.
     * @param {string} baseUri base domain
     * @param {string} uniqueUri full unique url including domain
     * @returns {string} unique part of the url after excluding the baseUri
     */
    static getUniqueUriFromBase(baseUri, uniqueUri) {
        const baseParts = baseUri.split('/').map(x => x.trim()).filter(x => x);
        const uniqueParts = uniqueUri.split('/').map(x => x.trim()).filter(x => x);

        let uri = [];

        // loop through the unique parts in reverse order, until we match the baseUri
        for (let i = uniqueParts.length - 1; i >= 0; i--) {
            const part = uniqueParts[i];
            if (baseParts.includes(part)) {
                break;
            }
            uri.push(part);
        }

        return uri.reverse().join('/');
    }

    /**
     * Get the base URI (for all browsers)
     * @returns {string} (e.g. /portal/)
     */
    static getBaseUri() {
        return getBaseUri();
    }
    
    /**
     * Check if a url is internal (within the site)
     * @param {string} url relative url string withing the site
     * @returns {boolean} Whether the link is internal 
     */
    static isInternalLink(url) {
        return url && url.indexOf('.') < 0;
    }
}

/**
 * Function to convert a text name (like a title) into a URL friendly slug.
 * If we start to have more use cases we can switch to the "slugify" package via NPM.
 * @param {string} text string to transform in to slug format
 * @returns {string} slugyfied text
 */
export function slugify(text) {
    const uriEncodedCharRegex = /%[a-z0-9]{2}/ig;
    return encodeURIComponent(text.trim().replace(/\s/ig, '-').toLowerCase()).replace(uriEncodedCharRegex, '-');
}