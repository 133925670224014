import { DateTime } from 'luxon';

/**
 * Standardize the formats we use to display dates and times
 */
export const FORMAT = {
    DATE_SHORT: 'd LLL yyyy',
    DAY: 'd',
    DAYOFWEEK: 'EEEE',
    DAYOFWEEK_SHORT: 'EEE',
    MONTH_SHORT: 'LLL',
    MONTH_LONG: 'LLLL',
    TIME_24HR: 'HH:mm',
    TIMEZONE: 'ZZZZ',
    YEAR: 'yyyy'
};

/**
 * Attempt to create a DateTime object from the passed string
 * @param {string} dateString ISO formatted date string
 * @returns {boolean} true if created a DateTime object successfully; false otherwise
 */
export function isDateTime(dateString) {
    let date = DateTime.fromISO(dateString);
    if (date.invalidReason) {
        return false;
    }
    return true;
}

/**
 * Find the number of days in a date's month
 * @param {string} dateString ISO formatted date string
 * @returns {number} days in month
 */
export function getDaysInMonth(dateString) {
    let date = DateTime.fromISO(dateString);
    if (date.invalidReason) {
        return null;
    }
    return DateTime.local(date.year, date.month).daysInMonth;
}

/**
 * Get the year in a given date
 * @param {string} dateString ISO formatted date string
 * @returns {number} year
 */
export function getDateYear(dateString) {
    let date = DateTime.fromISO(dateString);
    if (date.invalidReason) {
        return null;
    }
    return date.year;
}

/**
 * Get the day of the month
 * @param {string} dateString ISO formatted date string
 * @returns {number} day in month
 */
export function getDayOfMonth(dateString) {
    let date = DateTime.fromISO(dateString);
    if (date.invalidReason) {
        return null;
    }
    return date.day;
}

/**
 * Get the month from date
 * @param {string} dateString ISO formatted date string
 * @returns {number} month
 */
export function getMonthAsNumber(dateString) {
    let date = DateTime.fromISO(dateString);
    if (date.invalidReason) {
        return null;
    }
    return date.month;
}

/**
 * Get the time of an event from a date string
 * @param {string} dateString ISO formatted date string
 * @returns {string} date string in hh:mm format
 */
export function getTime(dateString) {
    let date = DateTime.fromISO(dateString);
    if (date.invalidReason) {
        return dateString;
    }
    return date.toLocaleString({
        hour: '2-digit',
        minute: '2-digit'
    });
}

/**
 * Get the time of an event from a date string (forced into 24hr format)
 * @param {string} dateString ISO formatted date string
 * @returns {string} date string in hh:mm format
 */
export function get24HourTime(dateString) {
    let date = DateTime.fromISO(dateString);
    if (date.invalidReason) {
        return dateString;
    }
    return date.toLocaleString({
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    });
}

/**
 * Get the day from a date string
 * @param {string} dateString ISO formatted date string
 * @returns {string} the day of date in numeric format
 */
export function getDay(dateString) {
    let date = DateTime.fromISO(dateString);
    if (date.invalidReason) {
        return dateString;
    }
    return date.toLocaleString({
        day: 'numeric'
    });
}

/**
 * Get the day from a date string - the replace Regex is for removing any non word characters which Edge and IE tent to put on
 * @param {string} dateString ISO formatted date string
 * @returns {string} the day of date as string in long format
 */
export function getDayLong(dateString) {
    const date = DateTime.fromISO(dateString);
    if (date.invalidReason) {
        return dateString;
    }
    return (date.toLocaleString({
        weekday: 'long'
    })).replace(/\W/ig, '');
}

/**
 * Get the month of an event from a date string
 * @param {string} dateString ISO formatted date string
 * @returns {string} the full name of the month 
 */
export function getMonth(dateString) {
    let date = DateTime.fromISO(dateString);
    if (date.invalidReason) {
        return dateString;
    }
    return date.toLocaleString({
        month: 'long'
    });
}

/**
 * Format an ISO date string to date with abbreviated month e.g. Aug 6, 2014
 * @param {string} dateString as ISO formatted string
 * @returns {string} a string formatted to localized date with abbreviated month
 * see https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
 */
export function formatDateToDD(dateString) {
    let date = DateTime.fromISO(dateString);
    if (date.invalidReason) {
        return dateString;
    }
    return date.toFormat('DD');
}

/**
 * Format iso date string to short localized date and time e.g. Aug 6, 2014, 1:07 PM
 * @param {string} dateString as ISO formatted string
 * @returns {string} date string formatted to short or the initial string if DateTime object
 * cannot be instantiated correctly
 */
export function formatToShort(dateString) {
    let date = DateTime.fromISO(dateString);
    // just return the string if the date can't be parsed for any reason
    if (date.invalidReason) {
        return dateString;
    }
    return date.toFormat('ff');
}

/**
 * Format an ISO date string to numeric day, long month and year e.g. July 30, 2018
 * @param {string} dateString as ISO formatted string
 * @returns {string} date string formatted to numeric day, long month and year
 */
export function formatDateToLongMonth(dateString) {
    let date = DateTime.fromISO(dateString);
    if (date.invalidReason) {
        return dateString;
    }
    return date.toLocaleString({
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });
}

/**
 * Format a date string to include 2 digit hours and minutes
 * @param {string} dateString as ISO formatted string
 * @returns {string} date string to include day in numeric, long month, full year and 2 digit hours and minutes
 */
export function formatDateToTwoDigitHour(dateString) {
    let date = DateTime.fromISO(dateString);
    if (date.invalidReason) {
        return dateString;
    }
    return date.toLocaleString({
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
}

/**
 * Format an ISO date string
 * @param {string} dateString as ISO formatted string
 * @param {string} formatString the options to be used for formatting the date e.g. 'HH:mm DD ZZZZ'
 * @returns {string} date string to include day in numeric, long month,full year and 2 digit hours and minutes
 */
export function formatDate(dateString, formatString) {
    let date = DateTime.fromISO(dateString);
    if (date.invalidReason) {
        return dateString;
    }
    return date.toFormat(formatString);
}

/**
 * Format an ISO date string after it has been converted to a local date
 * @param {string} dateString as ISO formatted string
 * @param {string} formatString the options to be used for formatting the date e.g. 'HH:mm DD ZZZZ'
 * @returns {string} date string to include day in numeric, long month,full year and 2 digit hours and minutes
 */
export function formatLocalDate(dateString, formatString) {
    let date = DateTime.fromISO(dateString);
    if (date.invalidReason) {
        return dateString;
    }

    let localDate = convertToLocal(date);
    return localDate.toFormat(formatString);
}

/**
 * Format an ISO date string with a timezone
 * @param {string} dateString as ISO formatted string
 * @param {string} formatString the options to be used for formatting the date e.g. 'HH:mm DD ZZZZ'
 * @param {string} timezone the timezone for the ISO string
 * @returns {string} date string to include day in numeric, long month,full year and 2 digit hours and minutes
 */
export function formatDateWithTimezone(dateString, formatString, timezone) {
    let overrideZone = DateTime.fromISO(dateString, { zone: timezone });
    if (overrideZone.invalidReason) {
        // attempt to return in local time as an IE fix just to make the dates look nicer
        return formatDate(dateString, formatString);
    }

    // if we don't want the short timezone - return using the format string provided
    if (formatString.indexOf('ZZZZ') === -1 || formatString.indexOf('ZZZZZ') > -1) {
        return overrideZone.toFormat(formatString);
    }

    // work around for rubbish timezone handling 
    const formatPlaceholder = '@@@@';

    // replace the short timezone format because it doesn't work
    formatString = formatString.replace('ZZZZ', formatPlaceholder);
    let returnString = overrideZone.toFormat(formatString);

    // use a different format string to get the short timezone
    let otherString = overrideZone.toFormat('TTT');
    let shortTimezone = otherString.substring(otherString.indexOf(' ') + 1);

    return returnString.replace(formatPlaceholder, shortTimezone);
}

/**
 * Compare timezone with current timezone to determine if they are the same
 * @param {string} timezone the timezone being provided
 * @returns {boolean} true/false if the timezone matches the local (browser) timezone
 */
export function isSameTimezone(timezone) {
    let localTimezone = DateTime.local().zoneName;
    if (localTimezone !== timezone) {
        return false;
    } else {
        return true;
    }
}

/**
 * Create a new DateTime object 
 * @param {string} dateString in ISO format
 * @returns {object} luxon DateTime
 */
export function newDateFromISO(dateString) {
    let date = DateTime.fromISO(dateString);
    if (date.invalidReason) {
        return dateString;
    }
    return date;
}

/**
 * Create a local DateTime
 * @returns {object} luxon DateTime
 */
export function getLocalDate() {
    return DateTime.local();
}

/**
 * Set the date's zone to the host's local zone.
 * @param {string} dateString in ISO format
 * @returns {object} newly-constructed luxon DateTime
 */
export function convertToLocal(dateString) {
    let date = DateTime.fromISO(dateString).toLocal();
    if (date.invalidReason) {
        return dateString;
    }
    return date;
}

/**
 * Create a DateTime from a Javascript Date object. Uses the default zone.
 * @returns {object} luxon DateTime
 */
export function newDateTimeFromJSObject() {
    return DateTime.fromJSDate(new Date());
}

/**
 * Create a DateTime from a Javascript Date object.
 * @param {Date} date a JSDate object
 * @returns {object} luxon DateTime
 */
export function jsDateToDateTime(date) {
    return DateTime.fromJSDate(date);
}

/**
 * Add a number of days to a supplied DateTime instance
 * @param {DateTime} date the date to which the days are added
 * @param {number} numberOfDays to be added to the date
 * @returns {object} luxon DateTime
 */
export function addDaysToDate(date, numberOfDays) {
    return date.plus({ days: numberOfDays });
}

/**
 * Add a number of weeks to a supplied DateTime instance
 * @param {DateTime} date the date to which the days are added
 * @param {number} numberOfWeeks to be added to the date
 * @returns {object} luxon DateTime
 */
export function addWeeksToDate(date, numberOfWeeks) {
    return date.plus({ weeks: numberOfWeeks });
}

/**
 * Get the millis difference between two DateTime objects
 * @param {object} start luxon DateTime object
 * @param {object} end luxon DateTime object
 * @returns {number} the milliseconds between the two params
 */
export function getDiffInMillis(start, end) {
    return start.diff(end).toObject().milliseconds;
}

/**
 * Function for handling dates that we get from the Library's API in the format: 20180918113000
 * @param {string} date The date in the ~crazy~ library format
 * @param {string} [format] The format to return the parsed date in
 * @returns {string} The parsed and formatted date
 */
export function handleLibraryDates(date, format) {
    const d = DateTime.fromFormat(date.toString(), 'yyyyMMddHHmmss');
    if (!d.isValid) {
        /*
         * should we just return null at this point so the
         * user doesn't see anything odd?
         */
        return date;
    }
    if (format) {
        return d.toFormat(format);
    }
    return d.toFormat('HH:mm, dd MMM yyyy');
}

/**
 * Extracts a text date and returns it as a DateTime object
 * @param {string} date The date string to parse
 * @param {string} format The format the string is expected to be in
 * @returns {DateTime} datetime object based on the specified string date and format 
 */
export function fromFormat(date, format) {
    const d = DateTime.fromFormat(date, format, { locale: 'en-GB' });
    if (!d.isValid) {
        return date;
    }
    return d;
}

/**
 * Sets the time for a given date returns it
 * @param {object} date A DateTime Luxon object
 * @param {string} hour The hour to set the date to in 24h format e.g. '08'
 * @param {string} minute The minute to set the date to e.g. '08'
 * @returns {object} A new Luxon DateTime object
 */
export function setTime(date, hour, minute) {
    const d = date;
    if (!d.isValid) {
        return date;
    }
    return d.set({ hour: hour, minute: minute });
}

/**
 * Get the time in seconds since the date passed in ...
 * ...if date older than a week just return the date as 'dd-mm-yyyy'
 * @param {string} date The date expected to be UTC
 * @returns {string} The parsed and formatted date
 */
export function calculateTimeSinceDate(date) {
    const itemDate = convertToLocal(date);
    const now = DateTime.local();

    // check how old 'date' is in millis/seconds
    const millis = now.diff(itemDate).toObject().milliseconds;
    const diff = Math.floor(millis / 1000);

    // prepare string response
    if (diff <= 1) {
        return 'just now';
    }
    if (diff < 20) {
        return diff + ' secs';
    }
    if (diff < 40) {
        return '30 secs';
    }
    if (diff <= 90) {
        return '1 min';
    }
    if (diff <= 3540) {
        return Math.round(diff / 60) + ' mins';
    }
    if (diff <= 5400) {
        return '1 hour';
    }
    if (diff <= 86400) {
        return Math.round(diff / 3600) + ' hours';
    }
    if (diff <= 129600) {
        return '1 day';
    }
    if (diff < 604800) {
        return Math.round(diff / 86400) + ' days';
    }
    if (diff <= 777600) {
        return '1 week';
    }

    // when 'date' is older than 1 week just format and return it
    return 'on ' + itemDate.toFormat('dd LLL y');
}

/**
 * Get the descriptive timezone name
 * @returns {string} descriptive timezone name
 */
export function getTimezoneName() {
    return DateTime.local().zoneName;
}

/**
 * Work out if a day of the week occurs in a date range
 * @param {string} date1 ISO formatted date string
 * @param {string} date2 ISO formatted date string
 * @param {number} day weekday number
 * @returns {boolean} whether day of the week occurs in a date range
 */
export function hasDayOfWeek(date1, date2, day) {
    let d1 = convertToLocal(date1);
    let d2 = convertToLocal(date2);
    while (d1 < d2) {
        if (d1.weekday === day) {
            return true;
        }
        // move onto the next day
        d1 = d1.plus({ days: 1 });
    }
    
    // check the end day if we overshot adding on 1 day
    if (d2.weekday === day) {
        return true;
    }

    return false;
}