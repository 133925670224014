import { createRouter, createWebHistory } from 'vue-router';
import { checkAuthQueryString, validateJwt, getScrollBehaviour } from '@core-portal/scripts/router.helper';

import { useIdentityStore } from '@stores/identity.module';
import { useTokenStore } from '@core-portal/stores/token.module';

import { SITEMODE } from '@config/constants';

import UrlHelper from '@utilities/UrlHelper';
import { isDeployRoot, setPageTitleSiteMode, getSiteModeFromPath, getRedirectPathForSiteMode } from '@utilities/SiteHelper';

/**
 * Function that encapsualtes the full setting up of a VueRouter object
 * @param {object[]} routes list of objects containing route names
 * @returns {object} vue router instance object
 */
export function initRouter(routes) {
   
    const router = new createRouter({
        history: createWebHistory(),
        routes,
        scrollBehavior(to, from, savedPosition) {
            const scrollBehavior = getScrollBehaviour();

            // check if we navigating to an anchor
            if (to.hash) {
                return {
                    el: to.hash,
                    scrollBehavior
                };
            }

            /*
             * scroll to the top of the page on each navigation if there is not a
             * previously saved position in the history.
             */
            return savedPosition ?? { left: 0, top: 0, scrollBehavior };
        }
    });

    router.beforeEach(routerPreNext);

    return router;
}

// Save all the elements for reference
const dropdownElements = Array.from(document.querySelectorAll('a.org-js-mega-menu-dropdown'));
const dropdownPanels = Array.from(document.querySelectorAll('div.org-js-mega-menu-panel'));
const topLevelLinks = Array.from(document.querySelectorAll('.org-mega-menu--root__link'));

// The mega menu style classes
const MEGA_MENU_PANEL_ACTIVE_CLASS = 'org-js-mega-menu-panel--active';
const MEGA_MENU_PANEL_HIDE_CLASS = 'org-js-mega-menu-panel--hidden';
const ROUTER_LINK_ACTIVE_CLASS = 'router-link-active';
const ROUTER_LINK_ACTIVE_EXACT_CLASS = 'router-link-exact-active';

/**
 * Callback to be passed to a VueRouter beforeEach function
 * @param {object} to destination route object
 * @param {object} from from route object
 * @param {object} next callback method to be called within this hook
 * @returns {void}
 */
export async function routerPreNext(to, from, next) {

    handleMegaMenu(to);

    // check for server 404 errors
    if (to.query.aspxerrorpath) {
        let notFoundUri = to.query.aspxerrorpath;

        // check that we are not duplicating the uri
        notFoundUri = UrlHelper.getUniqueUriFromBase(UrlHelper.getBaseUri(), notFoundUri);

        return next({ path: notFoundUri, replace: true });
    }

    // check if the user is logging in
    let newRouteFromJwt = checkAuthQueryString(to);
    if (newRouteFromJwt) {
        return next(newRouteFromJwt);
    }

    // check if user needs to be redirected
    redirectToAlternativeSite();

    // does this route require the user to be authenticated
    const requiresAuth = to.matched.some(route => route.meta && route.meta.requiresAuth);

    // is this route only allowed for certain roles
    let restrictedByRoles = requiresAuth
        ? to.matched.find(route => route.meta && route.meta.allowedRoles)
        : null;
    restrictedByRoles = restrictedByRoles
        ? restrictedByRoles.meta.allowedRoles
        : restrictedByRoles;

    const tokenStore = useTokenStore();
    const identityStore = useIdentityStore();  // Declared locally

    // handle routes that require authentication
    if (requiresAuth) {
        /**
         * We need a profile before we can check for authentication
         * If the JWT has expired we have to get all the user details again.
         */
        if (tokenStore.jwtExpired || !identityStore.authenticated) {
            await validateJwt(to);
        }

        // check that the user belongs to one of the allowed roles
        if (restrictedByRoles) {
            const canView = restrictedByRoles.some(role => identityStore.profile.roles.includes(role.toLowerCase()));
            if (!canView) {
                return next({ name: 'unauthorised' });
            }
        }
    }

    /*
     * This goes through the matched routes from last to first, finding the closest route with a title.
     * eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
     */
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        let title = nearestWithTitle.meta.title;
        if (to.params) {
            title += to.params.slug ? ' - ' + to.params.slug.replace(/-/g, ' ').trim() : '';
        }
        document.title = title;
    }

    // Add the site mode/type name to the document title
    setPageTitleSiteMode();

    return next();
}

/**
 * Check the profile to see if we need to redirect to an alternative version
 */
function redirectToAlternativeSite() {
    // only redirect if we are in the deployment root
    if (isDeployRoot()) {
        const identityStore = useIdentityStore();  // Declared locally
        // check the user is allowed on this site
        let profile = identityStore.profile;
        if (profile) {
            // check if the user needs to be moved to the other version of the DSP site
            if (profile.isAuthenticated && !profile.isImpersonating) {
                let userSiteMode = identityStore.siteMode;
                if (userSiteMode === SITEMODE.BOTH) {
                    userSiteMode = SITEMODE.INTRANET;
                }

                let pathSiteMode = getSiteModeFromPath();

                if (userSiteMode !== pathSiteMode) {
                    // bounce to the other site keeping the full url
                    let fullPath = getRedirectPathForSiteMode(userSiteMode);
                    window.location = fullPath;
                }
            }
        }
    }
}

/**
 * Update the mega menu classes on navigation
 * @param {object} to destination route object 
 */
function handleMegaMenu(to) {
    // reset the dropdowns
    dropdownElements.forEach(link => link.classList.remove(MEGA_MENU_PANEL_ACTIVE_CLASS));
    dropdownPanels.forEach(panel => panel.classList.add(MEGA_MENU_PANEL_HIDE_CLASS));

    const match = Array.from(to?.matched)?.shift();
    const secondaryKey = match?.meta?.secondaryKey;

    if (!match && !secondaryKey) {
        return;
    }

    topLevelLinks.forEach(link => {
        // clear the match classes
        link.classList.remove(ROUTER_LINK_ACTIVE_EXACT_CLASS);
        link.classList.remove(ROUTER_LINK_ACTIVE_CLASS);

        // normalise the links
        const href = link.getAttribute('href');
        const matchPath = new URL(UrlHelper.getBaseUri() + match.path.replace(/^\//ig, ''));
        const matchKey = new URL(UrlHelper.getBaseUri() + secondaryKey);

        // re-add the match classes as needed
        if (matchPath.href.replace(matchPath.origin, '') === href || matchKey.href.replace(matchKey.origin, '') === href) {
            link.classList.remove(ROUTER_LINK_ACTIVE_EXACT_CLASS);
            link.classList.add(ROUTER_LINK_ACTIVE_CLASS);
        }
    });
}
