import { MEGA_MENU_CLASSES } from '@core-portal/config/constants';
import { tabRoundRobin } from '@scripts/keyboard-handler';
import { sendCustomEvent } from '@utilities/AnalyticsHelper.js';
import { EVENT_NAME } from '@config/constants';

/**
 * Initialisation of the mega menu panels
 */
export default function initMegaMenuPanels() {
    // The valid "states" a mega menu tab/panel pair can have
    const STATE = {
        EXPANDED: 'EXPANDED',
        HIDDEN: 'HIDDEN'
    };

    // The css class for an active tab
    const TAB_ACTIVE_CLASS = 'org-js-mega-menu-panel--active';
    const PANEL_HIDDEN_CLASS = 'org-js-mega-menu-panel--hidden';

    // Get all the "tab" elements
    const tabs = document.querySelectorAll('a[data-mega-menu-tab]');
    let activeTab = null;
    let activePanel = null;
    const focusable = {
        first: null,
        last: null
    };

    // Create a lookup so we don't have to keep querying the DOM
    let panelsLookup = {};
    document.querySelectorAll('div[data-mega-menu-panel]')
        .forEach(panel => panelsLookup[panel.id] = panel);

    // Loop through the tabs and add the event listener logic
    tabs.forEach(tab => {
        tab.addEventListener('click', e => {
            // stop the link doing it's thing
            e.preventDefault();

            activeTab = tab;

            // get the values we need to switch the state
            activePanel = panelsLookup[tab.dataset.megaMenuTabControls];
            const newState = activePanel.hasAttribute('hidden')
                ? STATE.EXPANDED
                : STATE.HIDDEN;

            // send GA if panel not already expanded
            if (newState === STATE.EXPANDED) {
                sendCustomEvent({
                    'event': EVENT_NAME.NAVIGATION,
                    'event_action_source': 'Mega Menu',
                    'event_label': tab.textContent
                });
            }

            switchState(tab, activePanel, newState);

            if (newState === STATE.EXPANDED) {
                setTimeout(() => document.addEventListener('click', clickHandler, true), 10);
            }
        });
    });

    /**
     * Function to encapsulate all the switching logic
     * @param {HTMLElement} tab element
     * @param {HTMLElement} panel element
     * @param {string} newState EXPANDED or HIDDEN determine the DOM manipulation applied to the html to switch visibility
     */
    function switchState(tab, panel, newState) {
        let links = null;
        switch (newState) {
        case STATE.EXPANDED:
            // first reset any active tabs/panels
            switchState(null, null, STATE.HIDDEN);

            panel.removeAttribute('hidden');
            tab.classList.add(TAB_ACTIVE_CLASS);
            panel.classList.remove(PANEL_HIDDEN_CLASS);

            // set panel's first and last link for circular tabbing
            links = panel.getElementsByTagName('a');
            focusable.first = links[0];
            focusable.last = links[links.length - 1];
            // focus first item in the panel so user can tab through links
            focusable.first?.focus();

            document.addEventListener('keydown', keyboardHandler, false);
            break;
        case STATE.HIDDEN:
            panel?.setAttribute('hidden', 'true')
                ?? Object.values(panelsLookup).forEach(p => p.setAttribute('hidden', 'true'));

            panel?.classList.add(PANEL_HIDDEN_CLASS);

            tab?.classList.remove(TAB_ACTIVE_CLASS)
                ?? tabs.forEach(t => t.classList.remove(TAB_ACTIVE_CLASS));

            document.removeEventListener('keydown', keyboardHandler, false);
            document.removeEventListener('click', clickHandler, true);
            break;
        }
    }

    /**
     * Handler for any keyboard events
     * @param {KeyboardEvent} event triggered by key press
     */
    function keyboardHandler(event) {
        switch (event.key.toLowerCase()) {
        case 'escape':
            switchState(null, null, STATE.HIDDEN);
            activeTab.focus();
            activeTab = null;
            break;
        case 'tab':
            // Keep the user in the panel until they want to leave via ESC
            tabRoundRobin(focusable.first, focusable.last, event);
            break;
        default:
            break;
        }
    }

    /**
     * Listen for "off" click events to close any open
     * panels if the user clicks off the mega menu
     * @param {MouseEvent} event triggered by click action
     */
    function clickHandler(event) {

        for (let elem = event.target; elem; elem = elem.parentNode) {
            if (!elem.className) {
                continue;
            }

            // if the element contains one of our mega menu classes then we are ok
            if (MEGA_MENU_CLASSES.some(c => elem.classList.contains(c))) {
                return;
            }

            // otherwise reset everything
            switchState(null, null, STATE.HIDDEN);
        }
    }
}