
import { defineStore } from 'pinia';

export const useNewsStore = defineStore('news', {
    state: () => ({
        newsPageIndex: 0,
        newsListItems: [],
        newsListTitle: null,
        newsListEnd: false,
        bannerSlugSet: [],
        newsRowData: {},
        newsSliderFeed: [],
        gtmNewsTags: []
    }),
    persist: true,

    getters: {
        /**
         * Get news banner items slugs
         * @param {object} state app state instance
         * @returns {object[]} The slugs of all items displayed in the banner
         */
        newsBannerSlugs: (state) => state.bannerSlugSet,

        /**
         * Get current page index when paging news data
         * @param {object} state app state instance
         * @returns {number} the current page index on the `AllNews` template 
         */
        pageIndex: (state) => state.newsPageIndex,

        /**
         * Get the news data for the All News page
         * @param {object} state app state instance
         * @returns {object[]} the list of news items to render on the `AllNews` template
         */
        allNewsItems: (state) => state.newsListItems,

        /**
         * Get the title for the AllNews page
         * @param {object} state app state instance
         * @returns {string} the title to display on the `AllNews` template
         */
        allNewsTitle: (state) => state.newsListTitle ? state.newsListTitle : 'News',

        /**
         * Check if all the news items been fetched from the API
         * @param {object} state app state instance
         * @returns {boolean} have all the news items been fetched from the API on the `AllNews` template
         */
        allNewsEnd: (state) => state.newsListEnd,

        /**
         * Get news row data by news row id
         * @param {object} state app state instance
         * @returns {object} The news row data for a given id
         */
        getNewsRowDataById: (state) => (id) => state.newsRowData[id],

        /**
         * Get the news slider data feed
         * @param {object} state app state instance
         * @returns {object[]} list of news items
         */
        newsSliderData: (state) => state.newsSliderFeed || [],
    },

    actions: {
        /**
         * Reset the state to its initial values
         */
        reset() {
            this.$reset();
        },
        /**
         * Reset specific state properties to their initial values (Lite reset)
         */
        resetNewsListLite() {
            this.newsListItems = [];
            this.newsListTitle = null;
            this.newsListEnd = false;
            this.newsPageIndex = 0;  // Assuming the initial value is 0
        },
        /**
         * Update the page index
         * @param {number} value The new page index
         */
        setNewsPageIndex(value) {
            this.newsPageIndex = value;
        },
        /**
         * Update the list of news items
         * @param {object[]} value The new list of news items
         */
        setNewsListItems(value) {
            this.newsListItems = value;
        },
        /**
         * Update the title of the news list
         * @param {string} value The new title
         */
        setNewsListTitle(value) {
            this.newsListTitle = value;
        },
        /**
         * Set whether all news items have been fetched
         * @param {boolean} value The new value indicating if all items have been fetched
         */
        setNewsListEnd(value) {
            this.newsListEnd = value;
        },
        /**
         * Save the slugs of banner items
         * @param {object[]} value The new slugs of banner items
         */
        setNewsBannerSlug(value) {
            this.bannerSlugSet = value;
        },
        /**
         * Save news row data for a given id
         * @param {object} payload The payload containing id and data
         * @param {string} payload.id The id of the news row
         * @param {object} payload.data The data of the news row
         */
        setNewsRowData({ id, data }) {
            this.newsRowData[id] = data;
        },
        /**
         * Reset the news row data
         */
        resetNewsRowData() {
            this.newsRowData = {};
        },
        /**
         * Save the news slider data feed
         * @param {object[]} feed The new news slider feed
         */
        setNewsSliderData(feed) {
            this.newsSliderFeed = feed;
        },
        /**
         * Save the news tags for GA filtering
         * @param {object[]} value The new news tags
         */
        setGtmNewsTags(value) {
            this.gtmNewsTags = value;
        },

    }

});

