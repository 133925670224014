/**
 * String array representing classes applied to the mega menu elements
 */
export const MEGA_MENU_CLASSES = [
    'org-mega-menu-categories',
    'org-grid-mega-menu-categories',
    'org-mega-menu-categories__category',
    'o-container--mega-menu-panel',
    'org-js-mega-menu-panel',
    'org-js-mega-menu-panel--active',
    'org-js-mega-menu-dropdown',
    'org-js-mega-menu-dropdown__svg',
    'org-mega-menu-mobile',
    'org-grid-mega-menu__griditem1'
];

export const MENU_TITLES = {
    POPULAR: 'Key Links',
    USER_LINKS: 'My Links',
    THEME: 'Theme'
};