import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useIdentityStore } from '@stores/identity.module';

import { getMegaMenuContent } from '@core-portal/api/menu.api.js';

const initialState = () => ({
    menu: {
        mega: [],
        popular: [],
        mobileBar: [],
        secondaryParents: null
    },
    secondaryKey: null
});

export const useMenuStore = defineStore('menu', () => {
    const route = useRoute();
    const identityStore = useIdentityStore();
    const authenticated = computed(() => identityStore.authenticated);

    const mega = ref(initialState().menu.mega);
    const popular = ref(initialState().menu.popular);
    const mobileBar = ref(initialState().menu.mobileBar);
    const secondaryParents = ref(initialState().menu.secondaryParents);

    const secondaryKey = computed(() => authenticated.value ? route.meta.secondaryKey : 'news');

    const secondaryMenu = computed(() => {
        if (secondaryParents.value && secondaryKey.value) {
            const parentId = secondaryParents.value[secondaryKey.value.replace('-', '')];

            const data = mega.value.find(i => {
                return i.id === parentId;
            });
            if (data && Array.isArray(data.children)) {
                return data.children;
            }
        }

        return [];
    });

    /**
     * Reset menu store state
     */
    function $reset() {
        mega.value = initialState().menu.mega;
        popular.value = initialState().menu.popular;
        mobileBar.value = initialState().menu.mobileBar;
        secondaryParents.value = initialState().menu.secondaryParents;
    }

    /**
     * Go and get the navigation data
     */
    async function fetch() {
        try {
            const data = await getMegaMenuContent();
            mega.value = data.mega;
            popular.value = data.popular;
            mobileBar.value = data.mobileBar;
            secondaryParents.value = data.secondaryParents;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }

    return { mega, popular, mobileBar, secondaryParents, secondaryMenu, secondaryKey, $reset, fetch };
});