/**
 * Commonly used code and constants for handling site actions (e.g. logout modal)
 */
import { useIdentityStore } from '@stores/identity.module';

// site action keys match the ones in the .cs files
export const SITE_ACTIONS = {
    NONE: 'SA_NONE',
    CHECK_REGISTRATION: 'checkRegistration',
    FULL_ACCOUNT: 'fullAccount',
    ILSP_PREFS: 'ilspPreference',
    TOKEN_EXPIRED: 'tokenExpired',
    READY_FOR_UNIVERSITY: 'readyForUniversity',
    CHECK_LOCATION: 'checkLocation',
    SECURITY_TRAINING: 'checkSecurityTraining',
    INTERNATIONAL_CHECKIN: 'internationalCheckIn',
    REREG_REMINDER: 'reRegistrationReminder',
    MFA_STATUS: 'mfaStatus',
    STUDENT_TRAINING: 'studentTraining',
    LOCATION: 'location'
};

export const MODAL_DISMISSED = 'DISMISSED';

/**
 * Get the current site action
 * @param {string} currentPath The route path for the current page
 * @returns {string} value denoting which site action to follow e.g. display security training reminder
 */
export function currentSiteAction(currentPath) {

    if (!hasSiteActions()) {
        return SITE_ACTIONS.NONE;
    }

    // list actions in order of priority so they will be displayed one by one

    // NB: token expiry ALWAYS comes first
    if (showSiteAction(SITE_ACTIONS.TOKEN_EXPIRED)) {
        return SITE_ACTIONS.TOKEN_EXPIRED;
    }

    // Modal Site Actions ALWAYS come second

    if (showSiteAction(SITE_ACTIONS.FULL_ACCOUNT)) {
        return SITE_ACTIONS.FULL_ACCOUNT;
    }

    if (showSiteActionNotOnPath(SITE_ACTIONS.CHECK_LOCATION, currentPath, '/cms/location-privacy-notice')) {
        return SITE_ACTIONS.CHECK_LOCATION;
    }

    if (showSiteAction(SITE_ACTIONS.CHECK_REGISTRATION)) {
        return SITE_ACTIONS.CHECK_REGISTRATION;
    }

    if (showSiteAction(SITE_ACTIONS.INTERNATIONAL_CHECKIN)) {
        return SITE_ACTIONS.INTERNATIONAL_CHECKIN;
    }

    if (showSiteAction(SITE_ACTIONS.ILSP_PREFS)) {
        return SITE_ACTIONS.ILSP_PREFS;
    }

    if (showSiteAction(SITE_ACTIONS.READY_FOR_UNIVERSITY)) {
        return SITE_ACTIONS.READY_FOR_UNIVERSITY;
    }

    if (showSiteAction(SITE_ACTIONS.REREG_REMINDER)) {
        return SITE_ACTIONS.REREG_REMINDER;
    }

    if (showSiteAction(SITE_ACTIONS.MFA_STATUS)) {
        return SITE_ACTIONS.MFA_STATUS;
    }

    if (showSiteAction(SITE_ACTIONS.SECURITY_TRAINING)) {
        return SITE_ACTIONS.SECURITY_TRAINING;
    }

    if (showSiteAction(SITE_ACTIONS.STUDENT_TRAINING)) {
        return SITE_ACTIONS.STUDENT_TRAINING;
    }

    if (showSiteAction(SITE_ACTIONS.LOCATION)) {
        return SITE_ACTIONS.LOCATION;
    }

    // default fallback
    return SITE_ACTIONS.NONE;
}

/**
 * Check if there are any site actions to alert to the user
 * @returns {boolean} whether there are site actions to be dispayd
 */
export function hasSiteActions() {
    if (!userSiteActions()) {
        return false;
    }

    let actions = userSiteActions();
    // check there are any keys in the site actions object
    return Object.keys(actions).length > 0 && actions.constructor === Object ? true : false;
}

/**
 * Get the user site actions
 * @returns {object} object containing list of site actions as keys
 */
function userSiteActions() {
    const identityStore = useIdentityStore(); // Initialize locally when needed
    let userProfile = identityStore.profile;
    return userProfile ? userProfile.siteActions : null;
}

/**
 * Remove site action flags from browser session storage
 */
export function removeSiteActionModalSessionFlags() {
    sessionStorage.removeItem(SITE_ACTIONS.SECURITY_TRAINING);
    sessionStorage.removeItem(SITE_ACTIONS.CHECK_LOCATION);
    sessionStorage.removeItem(SITE_ACTIONS.READY_FOR_UNIVERSITY);
    sessionStorage.removeItem(SITE_ACTIONS.ILSP_PREFS);
    sessionStorage.removeItem(SITE_ACTIONS.FULL_ACCOUNT);
    sessionStorage.removeItem(SITE_ACTIONS.CHECK_REGISTRATION);
    sessionStorage.removeItem(SITE_ACTIONS.INTERNATIONAL_CHECKIN);
    sessionStorage.removeItem(SITE_ACTIONS.REREG_REMINDER);
    sessionStorage.removeItem(SITE_ACTIONS.MFA_STATUS);
    sessionStorage.removeItem(SITE_ACTIONS.STUDENT_TRAINING);
    sessionStorage.removeItem(SITE_ACTIONS.LOCATION);
}

/**
 * Cancel a site action. Clears the store and (optional) session storage
 * @param {string} siteAction name (e.g. 'fullAccount')
 * @param {boolean} saveToSessionStore (optional) save the flag to the session store to pass between hybrid pages
 */
export function cancelSiteAction(siteAction, saveToSessionStore = true) {

    // clear the store
    setSiteAction(siteAction, false);

    if (saveToSessionStore) {
        /*
         * Set site action modal dismissed flag in the browser session storage.
         * Stops modal appearing when moving between MVC routes during current session.
         */
        sessionStorage.setItem(siteAction, MODAL_DISMISSED);
    }
}

/**
 * Site the site action value in the store
 * @param {string} siteAction name (e.g. 'fullAccount') to set as object key
 * @param {boolean} flag the true/false value to save for the site action
 */
export function setSiteAction(siteAction, flag) {
    const identityStore = useIdentityStore(); // Initialize locally when needed
 
    identityStore.setSiteAction({ key: siteAction, flag });
}

/**
 * Calculate if we should show a site action
 * @param {string} siteAction name (e.g. 'fullAccount')
 * @returns {boolean} whether siteAction should display
 */
function showSiteAction(siteAction) {
    const identityStore = useIdentityStore(); // Initialize locally when needed
    if (!hasSiteActions()) {
        return false;
    }
    let storeRawValue = identityStore.getSiteAction(siteAction);
    let storeResult = storeRawValue ? storeRawValue : false;

    // Check if site action modal was already dismissed by user
    let sessionResult = sessionStorage.getItem(siteAction) === MODAL_DISMISSED;
    return storeResult && !sessionResult;
}

/**
 * Calculate if we should show a site action, but not on the path provided
 * @param {string} siteAction name (e.g. 'fullAccount')
 * @param {string} currentPath The route path for the current page
 * @param {string} excludePath The path to exclude the site action from appearing
 * @returns {boolean} if site action should display on current path
 */
function showSiteActionNotOnPath(siteAction, currentPath, excludePath) {
    let basicSiteAction = showSiteAction(siteAction);
    return basicSiteAction && !isOnPath(currentPath, excludePath);
}

/**
 * Check if the current path is a match for the exclude path
 * @param {string} currentPath The route path for the current page
 * @param {string} excludePath The path to exclude the site action from appearing
 * @returns {boolean} true/false flag
 */
function isOnPath(currentPath, excludePath) {

    // prevent a site action from popping up and then disappearing because of the base route
    if (!currentPath) {
        return true;
    }

    return currentPath.startsWith(excludePath);
}