// map Indigo icon names to Icon Library component names 
const _MAPPED_ICON_NAMES = () => ({
    answers: 'Info',
    addlink: 'PlusCircle',
    addressIcon: 'IdCard',
    bookIcon: 'Book2',
    campusbusiness: 'StoreAlt',
    complete: 'Check',
    content: 'FileAltRegular',
    directions: 'Directions2',
    email: 'Envelope2',
    event: 'CalendarAltRegular',
    faBookOpen: 'BookOpen',
    faCalendar: 'Calendar2',
    faCar: 'Car',
    faColumns: 'Columns',
    faCommentAlt: 'CommentAltRegular',
    faExchangeAlt: 'ExchangeAlt',
    faHome: 'Home',
    faListAlt: 'ListAltRegular',
    faNewspaper: 'Newspaper',
    faShoppingCart: 'ShoppingCart',
    faUser: 'User',
    file: 'FileRegular',
    ['file-alt']: 'FileAltRegular',
    ['file-archive']: 'FileArchiveRegular',
    ['file-audio']: 'FileAudioRegular',
    ['file-code']: 'FileCodeRegular',
    ['file-excel']: 'FileExcelRegular',
    ['file-image']: 'FileImageRegular',
    ['file-pdf']: 'FilePdfRegular',
    ['file-powerpoint']: 'FilePowerpointRegular',
    ['file-video']: 'FileVideoRegular',
    ['file-word']: 'FileWordRegular',
    fileIcon: 'FileAlt',
    infoIcon: 'Info',
    inprogress: 'Spinner',
    job: 'Suitcase',
    jiraform: 'ClipboardList',
    location: 'MapMarkerAlt',
    message: 'Comment',
    mpc: 'BookOpen',
    news: 'FileInvoice',
    noticeboard: 'Sign',
    notstarted: 'Times',
    people: 'User',
    room: 'Building2',
    share: 'ShareAlt',
    tools: 'Desktop',
    tvIcon: 'Tv',
    userIcon: 'User',
    user: 'User',
    users: 'Users',
    website: 'Globe',
    check: 'Check'
});

/**
 * Import icon component from the Icon Library
 * First it tries to catch and map any unusual icon names from Indigo before importing from the Icon Library
 * @param {string} iconName name of icon to import
 * @returns {object} Vue icon component
 */
export function getIconComponent(iconName = 'Info') {
    // map 'non-standard' icon names to match icon library components
    let compName = _MAPPED_ICON_NAMES()[iconName] ?? iconName;

    // capitalise first letter to match icon library
    compName = compName.charAt(0).toUpperCase() + compName.slice(1);

    // New v1.4.1 icon names start with 'Icon'
    if (!compName.startsWith('Icon')) {
        compName = 'Icon' + compName;
    }

    return () => {
        return import('@cisweb/icon-library-vue3')
            .then((l) => {
                let icon = l[compName];
                if (!icon) {
                    icon = l['IconInfo'];
                }
                return icon;
            });
    };
}