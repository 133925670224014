import Fetch from '@api/fetch';

/**
 * Get notifications from single or multiple endpoints.
 * Notifications returned are based on the user
 * @param {string} endpoints comma-separated list of endpoints to get
 * @returns {Promise} Promise object represents api response
 */
export function getNotifications(endpoints) {
    let url = '/api/notifications';
    if (endpoints) {
        url += `?endpoint=${encodeURIComponent(endpoints)}`;
    }
    return Fetch.internal.get(url);
}

/**
 * Get a notification by id
 * @param {number} id notification identifier
 * @returns {Promise} Promise object represents api response
 */
export function getNotificationById(id) {
    return Fetch.internal.get(`/api/notifications/id/${id}`);
}

/**
 * Mark that a notification has been delivered (read)
 * @param {string} endpoint the endpoint
 * @param {number[]} notificationIds Can be a single number of an array of numbers (notification ids)
 * @returns {Promise} Promise object represents api response
 */
export function updateNotificationDelivery(endpoint, notificationIds) {
    if (!Array.isArray(notificationIds)) {
        notificationIds = [notificationIds];
    }
    return Fetch.internal.put(`/api/notifications/${endpoint}`, notificationIds);
}
