<template>
    <span v-if="false" />
</template>
<script>
import { computed } from 'vue';
import { useTokenStore } from '@core-portal/stores/token.module';
import { ERROR_ROUTES } from '@routes/error';
import { clearAuthenticatedData } from '@core-portal/scripts/identity.helper';
import { NEWS_ROUTES } from '@routes/news';
import { setSiteAction, SITE_ACTIONS } from '@utilities/SiteActionHelper';
import { useMenuStore } from '@core-portal/stores/menu.module';

export default {
    name: 'TokenListener',
    setup() {
        const tokenStore = useTokenStore();
        const jwtExpired = computed(() => tokenStore.jwtExpired);
        const menuStore = useMenuStore();

        return {
            jwtExpired,
            menuStore
        };
    },
    computed: {
        showExpiryModal() {
            return this.$route.query?.expired
                    && this.$route.query.expired === 'true'
                    && this.$route.name !== ERROR_ROUTES.ACCESS_ENDED;
        }
    },
    watch: {
        jwtExpired: function (val) {
            if (val) {
                this.sessionExpired();
            }
        },
        showExpiryModal: function (val) {
            setSiteAction(SITE_ACTIONS.TOKEN_EXPIRED, val);
        }
    },
    methods: {
        /**
         * Token has expired - send user to expiry page
         */
        async sessionExpired() {
            clearAuthenticatedData(); // clear out the user authenticated data first
            // refresh MM data
            await this.menuStore.fetch();
            this.$router.push({ name: NEWS_ROUTES.HOME, query: { expired: 'true' } });
        }
    }
};
</script>