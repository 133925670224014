import { REQUEST_STATUS, PREFERENCES_SECTION } from '../config/constants';
import * as preferencesApi from '@api/preferences';
import { addUserLink, getUserLinks } from '@api/userLinks';
import { MODULE_DEFAULT, TRAVELSECTIONS_DEFAULT, JOBSECTIONS_DEFAULT } from '@config/preferenceDefaults.js';
import { defineStore } from 'pinia';
import { starredFilterOptions } from '@utilities/ModuleFiltering';

export const usePreferencesStore = defineStore('preferences', {
    state: () => ({
        modules: {
            data: {
                modules: {
                    sections: []
                }
            },
            status: null
        },
        travelSections: {
            data: [],
            status: null
        },
        jobSections: {
            data: [],
            status: null
        },
        userLinks: {
            data: [],
            status: null
        },
        generalData: {}
    }),

    //Getters
    getters: {
        // the module preferences
        modulePrefs: (state) => state.modules.data,
        modulePrefsStatus: (state) => state.modules.status,

        // the travel section preferences
        travelSectionsPrefs: (state) => state.travelSections.data,
        travelSectionsPrefsStatus: (state) => state.travelSections.status,

        // the job section preferences
        jobSectionsPrefs: (state) => state.jobSections.data,
        jobSectionsPrefsStatus: (state) => state.jobSections.status,

        // the userLinks preferences
        userLinksPrefs: (state) => state.userLinks.data,
        userLinksPrefsStatus: (state) => state.userLinks.status,

        /**
         * Retrieve preference data by key
         * @param {object} state app state instance
         * @returns {object} The preference content as { data, status }
         */
        generalDataByKey: (state) => (key) => state.generalData[key],
    },

    //Actions
    actions: {
        reset() {
            this.$reset();
        },

        requestPreferences(section) {
            this.setSectionStatus(section, REQUEST_STATUS.REQUEST);
        },

        successPreferences(section) {
            this.setSectionStatus(section, REQUEST_STATUS.SUCCESS);
        },

        failurePreferences(section) {
            this.setSectionStatus(section, REQUEST_STATUS.FAILURE);
        },

        defaultPreferencesStatus(section) {
            this.setSectionStatus(section, null);
        },

        applyModulePreferences(payload) {
            this.setSectionData(PREFERENCES_SECTION.MODULE, payload);
            this.setSectionStatus(PREFERENCES_SECTION.MODULE, REQUEST_STATUS.SUCCESS);
        },

        applyTravelSectionsPreferences(payload) {
            this.setSectionData(PREFERENCES_SECTION.TRAVELSECTIONS, payload);
            this.setSectionStatus(PREFERENCES_SECTION.TRAVELSECTIONS, REQUEST_STATUS.SUCCESS);
        },

        applyJobSectionsPreferences(payload) {
            this.setSectionData(PREFERENCES_SECTION.JOBSECTIONS, payload);
            this.setSectionStatus(PREFERENCES_SECTION.JOBSECTIONS, REQUEST_STATUS.SUCCESS);
        },

        applyUserLinksPreferences(payload) {
            this.setSectionData(PREFERENCES_SECTION.USERLINKS, payload);
            this.setSectionStatus(PREFERENCES_SECTION.USERLINKS, REQUEST_STATUS.SUCCESS);
        },

        setGeneralData(value) {
            this.generalData[value.key] = value.content;
        },

        setUserLinksData(preferences) {
            this.userLinks.data = preferences; // Assign new user links data
            this.userLinks.status = REQUEST_STATUS.SUCCESS; // Mark the status as successful
        },

        // Module Preferences

        async getModulePreferences() {
            this.requestPreferences(PREFERENCES_SECTION.MODULE);
            try {
                const preferences = await preferencesApi.getModulePreferences();
                if (!preferences) {
                    this.applyModulePreferences(deepClone(MODULE_DEFAULT));
                } else {
                    // default to "any" if the starred filter not set
                    preferences.modules.starredFilter = preferences.modules.starredFilter ?? starredFilterOptions.any.value;
                    const { sections: currentPreference } = preferences.modules;
                    const { sections: defaultPreference } = MODULE_DEFAULT.modules;

                    const missingKeys = defaultPreference.filter(p => !currentPreference.includes(p));
                    let newSections = new Set(missingKeys.concat(currentPreference));

                    currentPreference
                        .filter(p => !defaultPreference.includes(p))
                        .forEach(p => newSections.has(p) && newSections.delete(p));

                    preferences.modules.sections = Array.from(newSections);

                    this.applyModulePreferences(preferences);
                }
            } catch {
                // set default values
                this.defaultPreferencesStatus(PREFERENCES_SECTION.MODULE);
                this.applyModulePreferences(deepClone(MODULE_DEFAULT));
            }
        },

        async setModulePreferences(preferences) {
            this.requestPreferences(PREFERENCES_SECTION.MODULE);
            try {
                if (!preferences) {
                    preferences = deepClone(MODULE_DEFAULT);
                }
                await preferencesApi.updateModulePreferences(preferences);
                this.applyModulePreferences(preferences);
            } catch {
                this.failurePreferences(PREFERENCES_SECTION.MODULE);
            }
        },

        //Travel Section

        // Get Travel Sections Preferences
        async getTravelSectionsPreferences(authenticated = true) {
            if (!authenticated) {
                this.applyTravelSectionsPreferences(deepClone(TRAVELSECTIONS_DEFAULT));
                // reset staus when setting the default data so api is called upon authentication
                this.defaultPreferencesStatus(PREFERENCES_SECTION.TRAVELSECTIONS);

                return;
            }
            this.requestPreferences(PREFERENCES_SECTION.TRAVELSECTIONS);
            try {
                let userPreferences = await preferencesApi.getTravelSectionsPreference();

                // if we didn't get anything from the database - give the user the default sections
                if (!userPreferences || userPreferences.length === 0) {
                    this.applyTravelSectionsPreferences(deepClone(TRAVELSECTIONS_DEFAULT));
                } else {
                    let preferenceOrder = userPreferences.split(',');

                    // apply the ordering
                    if (preferenceOrder.length > 0) {
                        let orderedItems = [];

                        // find the sections that are in the user's preferences
                        preferenceOrder.map(prefId => {
                            let s = TRAVELSECTIONS_DEFAULT.find(ts => ts.id === prefId);
                            if (s) {
                                orderedItems.push(s);
                            }
                        });

                        // find the sections that were not in the user's preferences
                        TRAVELSECTIONS_DEFAULT.map(ts => {
                            // if the travel section is not in the ordered items
                            if (!orderedItems.some(oi => oi.id === ts.id)) {
                                orderedItems.push(ts);
                            }
                        });

                        this.applyTravelSectionsPreferences(orderedItems);
                    } else {
                        // despite having data from the database it couldn't be mapped so give the user the default preferences
                        this.applyTravelSectionsPreferences(TRAVELSECTIONS_DEFAULT);
                    }
                }
            } catch {
                this.failurePreferences(PREFERENCES_SECTION.TRAVELSECTIONS);
            }
        },

        // Set Travel Sections Preferences
        async setTravelSectionsPreferences(preferences) {
            this.requestPreferences(PREFERENCES_SECTION.TRAVELSECTIONS);
            try {
                if (preferences) {
                    this.applyTravelSectionsPreferences(preferences);
                    // we only want the id fields to save to the database
                    let prefIds = preferences.map(p => p.id).join(',');
                    await preferencesApi.updateTravelSectionsPreference(prefIds);
                } else {
                    // reset to default
                    this.applyTravelSectionsPreferences(deepClone(TRAVELSECTIONS_DEFAULT));
                    await preferencesApi.updateTravelSectionsPreference('');
                }
            } catch {
                this.failurePreferences(PREFERENCES_SECTION.TRAVELSECTIONS);
            }
        },

        // Get Job Sections Preferences
        async getJobSectionsPreferences() {
            this.requestPreferences(PREFERENCES_SECTION.JOBSECTIONS);
            try {
                const userPreferences = await preferencesApi.getJobSectionsPreference();

                // if we didn't get anything from the database - give the user the default sections
                if (!userPreferences) {
                    this.applyJobSectionsPreferences(deepClone(JOBSECTIONS_DEFAULT));
                } else {
                    const preferenceOrder = userPreferences.split(',');
                    const orderedItems = [];

                    // find the sections that are in the user's preferences
                    preferenceOrder.map(prefId => {
                        const section = JOBSECTIONS_DEFAULT.find(ts => ts.id === prefId);
                        if (section) {
                            orderedItems.push(section);
                        }
                    });

                    // find the sections that were not in the user's preferences
                    JOBSECTIONS_DEFAULT.map(ts => {
                        // if the job section is not in the ordered items
                        if (!orderedItems.some(oi => oi.id === ts.id)) {
                            orderedItems.push(ts);
                        }
                    });

                    this.applyJobSectionsPreferences(orderedItems);
                }
            } catch {
                this.failurePreferences(PREFERENCES_SECTION.JOBSECTIONS);
            }
        },

        // Set Job Sections Preferences
        async setJobSectionsPreferences(preferences) {
            this.requestPreferences(PREFERENCES_SECTION.JOBSECTIONS);
            try {
                if (preferences) {
                    this.applyJobSectionsPreferences(preferences);
                    // we only want the id fields to save to the database
                    let prefIds = preferences.map(p => p.id).join(',');
                    await preferencesApi.updateJobSectionsPreference(prefIds);
                } else {
                    // reset to default
                    this.applyJobSectionsPreferences(deepClone(JOBSECTIONS_DEFAULT));
                    await preferencesApi.updateJobSectionsPreference('');
                }
            } catch {
                this.failurePreferences(PREFERENCES_SECTION.JOBSECTIONS);
            }
        },

        // Get User Links Preferences
        async getUserLinksPreferences() {
            this.requestPreferences(PREFERENCES_SECTION.USERLINKS);
            try {
                const preferences = await getUserLinks();
                this.applyUserLinksPreferences(preferences);
            } catch {
                this.failurePreferences(PREFERENCES_SECTION.USERLINKS);
            }
        },

        // Set User Links Preferences
        setUserLinksPreferences(preferences) {
            this.requestPreferences(PREFERENCES_SECTION.USERLINKS);
            try {
                // save to store
                this.setUserLinksData(preferences);
            } catch {
                this.failurePreferences(PREFERENCES_SECTION.USERLINKS);
            }
        },

        // Add a User Link and Refresh Preferences
        async addUserLinkAndRefresh(link) {
            this.requestPreferences(PREFERENCES_SECTION.USERLINKS);
            try {
                await addUserLink(link);
                await this.getUserLinksPreferences();
                this.successPreferences(PREFERENCES_SECTION.USERLINKS);
            } catch {
                this.failurePreferences(PREFERENCES_SECTION.USERLINKS);
            }
        },

        /**
         * General Data GET
         * @param {string} key The preference key to retrieve data for
         */
        async getGeneralData(key) {
            const preferenceData = {
                key: key,
                content: {
                    data: null,
                    status: REQUEST_STATUS.REQUEST,
                },
            };
            try {
                this.setGeneralData(preferenceData); // save that we're starting to get the preferences
                const stringData = await preferencesApi.getPreference(key);
                preferenceData.content.data = JSON.parse(stringData);
                preferenceData.content.status = REQUEST_STATUS.SUCCESS;
                this.setGeneralData(preferenceData);
            } catch {
                preferenceData.content.data = null;
                preferenceData.content.status = REQUEST_STATUS.FAILURE;
                this.setGeneralData(preferenceData);
            }
        },

        /**
         * General Data SET
         * @param {object} value preference data model
         * @param {string} value.key The preference key
         * @param {object} value.data The preference data
         */
        async applyGeneralData(value) {
            const preferenceData = {
                key: value.key,
                content: {
                    data: value.data,
                    status: REQUEST_STATUS.SUCCESS,
                },
            };
            const submitDataString = JSON.stringify(preferenceData.content.data);
            try {
                this.generalData[preferenceData.key] = preferenceData.content;
                await preferencesApi.updatePreference(preferenceData.key, submitDataString);
            } catch {
                // silently fail
            }
        },

        // Helper function to set status for a specific section
        setSectionData(section, dataValue) {
            switch (section) {
            case PREFERENCES_SECTION.MODULE:
                this.modules.data = dataValue;
                break;
            case PREFERENCES_SECTION.TRAVELSECTIONS:
                this.travelSections.data = dataValue;
                break;
            case PREFERENCES_SECTION.JOBSECTIONS:
                this.jobSections.data = dataValue;
                break;
            case PREFERENCES_SECTION.USERLINKS:
                this.userLinks.data = dataValue;
                break;
            }
        },

        setSectionStatus(section, statusValue) {
            switch (section) {
            case PREFERENCES_SECTION.MODULE:
                this.modules.status = statusValue;
                break;
            case PREFERENCES_SECTION.TRAVELSECTIONS:
                this.travelSections.status = statusValue;
                break;
            case PREFERENCES_SECTION.JOBSECTIONS:
                this.jobSections.status = statusValue;
                break;
            case PREFERENCES_SECTION.USERLINKS:
                this.userLinks.status = statusValue;
                break;
            }
        },
    },

    persist: true,  // Enable persistence
});

/**
 * Local deep cloning to see how it goes in different browser versions...
 * @param {any} original value or object to clone
 * @returns {any} clonned original object or value
 */
function deepClone(original) {
    let clone = JSON.parse(JSON.stringify(original));
    return clone;
}

