import { defineStore } from 'pinia';
import { getEventTypes } from '@api/events.js';

export const useEventsStore = defineStore('events', {
    state: () => ({
        eventRowData: {},
        eventTypeOptions: []
    }),

    persist: true,

    getters: {
        /**
         * Retrieve a row of event data by channel id
         * @param {object} state app state instance
         * @returns {object[]} list of event items data
         */
        getEventRowData: (state) => {
            return (channel) => {
                return state.eventRowData[channel] || [];
                    
            };
        },
    },

    actions: {
        /**
         * Reset the state to initial state
         */
        reset() {
            this.$reset();
        },

        /**
         * Add data for an event row to the state
         * @param {object} value event row data model
         * @param {string} value.channel the channel id
         * @param {object[]} value.events the events data
         */
        setEventRowData(value) {
            this.eventRowData[value.channel] = value.events;
        },

        /**
         * Fetch and set event type options from API
         */
        async fetchEventTypeOptions() {
            try {
                const data = await getEventTypes();
                this.eventTypeOptions = data.items;
            } catch {
                this.eventTypeOptions = [{ 'value': 'all', 'text': 'All' }];
            }
        }
    }
});
