const InstitutionRedirect = () => import('@pages/mpc/InstitutionRedirect.vue');
const MpcSearch = () => import('@pages/mpc/MpcSearch.vue');
const ModuleDetails = () => import('@pages/mpc/ModuleDetails.vue');
const ModuleRedirect = () => import('@pages/mpc/ModuleRedirect.vue');
const ProgrammeDetails = () => import('@pages/mpc/ProgrammeDetails.vue');
const ProgrammeRedirect = () => import('@pages/mpc/ProgrammeRedirect.vue');

export const MPC_ROUTES = {
    HOME: 'MPC_HOME',
    SEARCH: 'MPC_SEARCH',
    MODULE: 'MPC_MODULE',
    MODULE_CURRENTYEAR: 'MPC_MODULE_CURRENTYEAR',
    PROGRAMME:  'MPC_PROGRAMME',
    PROGRAMME_CURRENTYEAR:  'MPC_PROGRAMME_CURRENTYEAR'
};

export const MPC_DEFAULT_TITLE = 'Module and Programme Catalogue';

export const MPC_PATHS = {
    BASE: '/mpc',
    MODULES: '/mpc/modules',
    PROGRAMMES: '/mpc/programmes'
};

export default [
    {
        path: MPC_PATHS.BASE,
        component: InstitutionRedirect,
        name: MPC_ROUTES.HOME,
        meta: {
            requiresAuth: true,
            title: MPC_DEFAULT_TITLE
        }
    },
    {
        path: `${MPC_PATHS.BASE}/:institutionId`,
        component: MpcSearch,
        name: MPC_ROUTES.SEARCH,
        meta: {
            requiresAuth: true,
            title: MPC_DEFAULT_TITLE
        }
    },
    {
        path: `${MPC_PATHS.MODULES}/:moduleId`,
        component: ModuleRedirect,
        name: MPC_ROUTES.MODULE_CURRENTYEAR,
        meta: {
            requiresAuth: true,
            title: 'Module Details'
        }
    },
    {
        path: `${MPC_PATHS.MODULES}/:moduleId/:yearId`,
        component: ModuleDetails,
        name: MPC_ROUTES.MODULE,
        meta: {
            requiresAuth: true,
            title: 'Module Details',
            subNav: true,
            subNavName: 'Module'
        }
    },
    {
        path: `${MPC_PATHS.PROGRAMMES}/:programmeId`,
        component: ProgrammeRedirect,
        name: MPC_ROUTES.PROGRAMME_CURRENTYEAR,
        meta: {
            requiresAuth: true,
            title: 'Programme Details'
        }
    },
    {
        path: `${MPC_PATHS.PROGRAMMES}/:programmeId/:yearId`,
        component: ProgrammeDetails,
        name: MPC_ROUTES.PROGRAMME,
        meta: {
            requiresAuth: true,
            title: 'Programme Details',
            subNav: true,
            subNavName: 'Programme'
        }
    }
];
