<template>
    <div
        class="o-modal modal-mask o-bg-white" 
        role="dialog" 
        aria-labelledby="dialog-title" 
        aria-describedby="dialog-description"
        aria-modal="true"
    >
        <div
            ref="baseModal"
            :class="wrapperClass"
            @click="handleWrapperClick"
            @keydown.prevent.tab.exact="switchFocus(true)"
            @keydown.prevent.tab.shift.exact="switchFocus(false)"
            @keyup.prevent.esc="close"
        >
            <div class="o-container modal-container">
                <div :class="headerClass">
                    <div class="modal-header u-align-left">
                        <p
                            id="dialog-description"
                            class="o-screen-reader"
                        >
                            {{ getDescription }}
                        </p>
                        <h2
                            v-if="getTitle"
                            id="dialog-title"
                            class="u-mar-b-0"
                        >
                            {{ getTitle }}
                        </h2>
                    </div>
                    <div
                        v-if="!hideXcloseButton"
                        class="u-align-right"
                    >
                        <button 
                            type="button"
                            class="e-icon o-modal-close"
                            aria-label="close dialog"
                            @click="close"
                        >
                            <IconTimes class="svg-inline--fa fa-w-11" />
                        </button>
                    </div>
                </div>
                <div class="modal-body o-bold-links o-bold-links--red">
                    <slot name="body" />
                </div>
                <div class="modal-footer">
                    <slot name="footer" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { IconTimes } from '@cisweb/icon-library-vue3';
import { nextTick } from 'vue';

export default {
    name: 'BaseModal',
    components: {
        IconTimes
    },
    props: {
        modalTitle: {
            type: String,
            required: false,
            default: ''
        },
        descriptionForScreenReaders: {
            type: String,
            required: true
        },
        hideXcloseButton: {
            type: Boolean,
            required: false,
            default:false
        }
    },
    emits: ['close'],
    data() {
        return {
            // keep track of the last focused element on the parent component
            focusedElementOnParent: null,
            // store all focusable elements passed to the modal component
            modalFocusableElements: null,
            // for targeting off clicks
            wrapperClass: 'modal-wrapper',
            index: 0
        };
    },
    computed: {
        getTitle() {
            return this.modalTitle;
        },
        getDescription() {
            return this.descriptionForScreenReaders;
        },
        headerClass() {
            /*
             * DJ comment (IF statement, using OBJECT syntax)
             * https://vuejs.org/v2/guide/class-and-style.html
             */
            const { hideXcloseButton: hideX } = this;

            return {
                // class : test 1 && test 2 && etc.
                'o-grid-modal': !hideX
            };

        }
    },
    
    created() {
        // find the last focused element on the parent component
        this.focusedElementOnParent = document.activeElement;
    },
    beforeUnmount() {
        // return the focus to the last focused element on the parent component
        if (this.focusedElementOnParent) {
            this.focusedElementOnParent.focus();
        }
    },

    async mounted() {
        // wait for slot content to get in the DOM
        await nextTick();
        // find all focuseable elements on the modal
        this.modalFocusableElements = this.findAllFocuseableEls();
        // Set focus on the first element
        this.focusFirstEl(this.modalFocusableElements);
    },
    methods: {
        /**
         * Indicate the modal can be closed, but don't include any payload
         */
        close() {
            this.$emit('close', false);
        },
        // get an array of fcuseable elements from the modal window
        findAllFocuseableEls() {
            return this.$refs.baseModal.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]');
        },
        /**
         * Set the focus on the first element in the array
         * @param {HTMLElement[]} elements array of focuseable elements contained by the modal
         */
        focusFirstEl(elements) {
            if (elements.length > 0) {
                elements[0].focus();
            }
        },
        /**
         * Set the focus on the last element in the array
         * @param {HTMLElement[]} elements array of focuseable elements contained by the modal
         */
        focusLastEl(elements) {
            if (elements.length > 0) {
                elements[elements.length - 1].focus();
            }
        },

        /**
         * Function to control what elements can
         * be tabbed to while the modal is open.
         * @param {boolean} [forward] forward tabbing order - defaults to true
         */
        switchFocus(forward = true) {
            let index = this.index;

            // if there aren't any focusable elements then quit
            if (!this.modalFocusableElements.length) {
                return;
            }

            // cycle through the focusable elements in the modal
            if (forward) {
                index < this.modalFocusableElements.length - 1 ? index++ : index = 0;
            } else {
                index === 0 ? index = this.modalFocusableElements.length - 1 : index--;
            }

            this.modalFocusableElements[index].focus();
            this.index = index;
        },

        /**
         * Close the modal if the user clicks outside it
         * @param {Event} event pointer event
         */
        handleWrapperClick(event) {
            const { target } = event;
            if (target.classList.contains(this.wrapperClass)) {
                this.close();
            }
        }
    }
};
</script>

<style scoped>
	/* do not address itcss namespace, as some of modal is third party */
    .modal-container {
        max-height: 90vh;
    }
</style>

