export const FEEDBACK_ROUTES = {
    ROOT: 'FEEDBACK_ROOT',
    PAGE: 'PAGE',
    SUCCESS: 'FEEDBACK_SUCCESS'
};

export default [
    {
        path: '/feedback',
        redirect: '/news'
    },
    {
        path: '/feedback/:cmsKey',
        component: () => import('@pages/feedback/FeedbackPage.vue'),
        name: FEEDBACK_ROUTES.PAGE,
        props: route => ({
            query: route.query?.q ?? null
        }),
        meta: {
            title: 'Feedback',
            // Specific group authorisation needs to be set in the CMS config table
            requiresAuth: true
        },
        children: [
            {
                path: '/feedback/:cmsKey/success',
                component: () => import('@pages/feedback/FeedbackSuccess.vue'),
                name: FEEDBACK_ROUTES.SUCCESS,
                meta: {
                    title: 'Feedback',
                    // Specific group authorisation needs to be set in the CMS config table
                    requiresAuth: true
                },
            }
        ]
    }
];