const NewsPreviewLanding = () => import('@pages/news/NewsPreviewLanding.vue');
const AllNews = () => import('@pages/news/AllNews.vue');
import { NEWS_ROUTES } from './news';

export default [
    {
        path: '/news-preview',
        name: NEWS_ROUTES.PREVIEW,
        component: NewsPreviewLanding,
        meta: {
            title: 'News Preview'
        }
    },
    {
        path: '/news-preview/college/:guid',
        name: NEWS_ROUTES.PREVIEW_COLLEGE,
        component: AllNews
    }
];