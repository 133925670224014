<template>
    <BaseModal
        v-if="displayModal"
        :modal-title="content.title"
        :description-for-screen-readers="content.screenReaderDescription"
        @close="closeModal"
    >
        <template #body>
            <div v-html="content.contentHtml" />
        </template>
        <template #footer>
            <div class="u-align-right">
                <GenericButton
                    v-if="content.link"
                    class="a-button--full-width-xxs"
                    flavour="secondary-red"
                    :link="content.link.url"
                    :title="'Go to ' + content.link.name"
                    @click="closeModal"
                >
                    <template #button-text>
                        {{ content.isButtonText ? content.link.name : 'Get started' }}
                    </template>
                </GenericButton>
                <GenericButton 
                    class="u-xxs-mar-t-1 a-button--full-width-xxs"
                    @click="closeModal"
                >
                    <template #button-text>
                        Continue to {{ siteName }}
                    </template>
                </GenericButton>
            </div>
        </template>
    </BaseModal>
</template>

<script>
import { REQUEST_STATUS } from '@config/constants';
import { getSiteActionContent, setSiteActionSnooze } from '@api/siteAction';
import { cancelSiteAction } from '@utilities/SiteActionHelper';

import GenericButton from '@atoms/buttons/GenericButton.vue';
import BaseModal from '@objects/BaseModal.vue';

export default {
    components: {
        GenericButton,
        BaseModal
    },
    props: {
        siteName: {
            type: String,
            required: true,
            default: 'Portal'
        },
        siteAction: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            content: null,
            contentRequestStatus: null
        };
    },
    computed: {
        displayModal() {
            return this.contentRequestStatus === REQUEST_STATUS.SUCCESS;
        }
    },
    watch: {
        siteAction: function () {
            this.getContent();
        },
    },
    beforeMount() {
        this.getContent();
    },
    methods: {
        async getContent() {
            try {
                this.contentRequestStatus = REQUEST_STATUS.REQUEST;
                this.content = await getSiteActionContent(this.siteAction);
                this.contentRequestStatus = REQUEST_STATUS.SUCCESS;
            } catch {
                this.contentRequestStatus = REQUEST_STATUS.FAILURE;
                this.closeModal(); // close the modal and allow another one to pop-up if we can't get the content at the moment
            }
        },
        async closeModal() {
            this.contentRequestStatus = null;
            if (this.content.canSnooze) {
                try {
                    await setSiteActionSnooze(this.siteAction);
                } catch {
                    // ignore error
                }
            }
            cancelSiteAction(this.siteAction);
        }
    }
};
</script>