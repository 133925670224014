import { defineStore } from 'pinia';
import { REQUEST_STATUS, NOTIFICATION_ENDPOINTS } from '@config/constants';
import { getNotifications } from '@api/notifications.api';
import { useIdentityStore } from '@stores/identity.module';
import { sendCustomEvent } from '@utilities/AnalyticsHelper.js';
import { EVENT_NAME } from '@config/constants';

export const useNotificationsStore = defineStore('notifications', {
    state: () => ({
        drawerOpen: false,
        data: {},
        userEndpoint: null
    }),
    persist: true,
    getters: {
        priorityNotification(state) {
            return getFirstItem(state.data, NOTIFICATION_ENDPOINTS.priority);
        },
        promotionNotification(state) {
            return getFirstItem(state.data, NOTIFICATION_ENDPOINTS.dspPromotion);
        },
        getNotifications(state) {
            return (endpoint) => {
                return state.data[endpoint]?.notifications ?? [];
            };
        },
        getRequestStatus(state) {
            return (endpoint) => {
                return state.data[endpoint]?.status ?? null;
            };
        },
    },
    actions: {
        reset() {
            this.$reset();
        },
        drawerSwitchState() {
            this.drawerOpen = !this.drawerOpen;
            if (this.drawerOpen) {
                // call GA event
                sendCustomEvent({
                    'event': EVENT_NAME.NOTIFICATION,
                    'event_action_source': 'Notifications Drawer',
                    'action': 'open',
                    'event_label': '',
                    'event_url': ''
                });
            }
        },
        async fetchPriorityNotifications() {
            await getData(this.data, NOTIFICATION_ENDPOINTS.priority);
        },
        async fetchPromotionNotifications() {
            await getData(this.data, NOTIFICATION_ENDPOINTS.dspPromotion);
        },
        async fetchUserNotifications() {
            if (!this.userEndpoint) {
                const identityStore = useIdentityStore();
                // calculate the user notification endpoint
                const staff = identityStore.staff;
                const student = identityStore.student;
                this.userEndpoint = calculateEndpoint(staff, student);
            }

            // fetch the notifications for the user endpoint
            return await this.fetchNotifications(this.userEndpoint);
        },
        async fetchNotifications(endpoints) {
            await getData(this.data, endpoints);
        },
        removeNotificationById(endpoints, id) {
            this.data[endpoints].notifications = this.getNotifications(endpoints).filter(n => n.notification_id !== id);
        }
    }
});

/**
 * Get notifications for a given set of endpoints
 * @param {object} data The general data property where all notifications are stored
 * @param {string} endpoints comma-separated list of endpoints to get
 */
async function getData(data, endpoints) {
    const identityStore = useIdentityStore();
    // check we have an endpoint to fetch
    if (!endpoints || !identityStore.authenticated) {
        return;
    }

    // set up the model for the endpoints we are going to query
    if (!data[endpoints]) {
        data[endpoints] = {
            notifications: null,
            status: null,
            lastFetchedUtc: null // will be stored as a string because Non-primitive types (i.e. Date) are not persisted 
        };
    }

    // don't fetch again if we are already fetching
    if (data[endpoints].status === REQUEST_STATUS.REQUEST) {
        return;
    }

    // don't refetch the data if it has already been fetched in the last minute
    if (data[endpoints].lastFetchedUtc) {
        const aMinuteAgo = new Date(Date.now() - 1000 * 60);
        const lastFetchedDt = new Date(data[endpoints].lastFetchedUtc);
        if (lastFetchedDt > aMinuteAgo) {
            return;
        }
    }
    try {
        data[endpoints].status = REQUEST_STATUS.REQUEST;
        data[endpoints].notifications = await getNotifications(endpoints);
        data[endpoints].status = REQUEST_STATUS.SUCCESS;
        data[endpoints].lastFetchedUtc = new Date().toISOString();
    } catch (e) {
        data[endpoints].status = REQUEST_STATUS.FAILURE;
        throw e;
    }
}

/**
 * Get the first item from a property key
 * @param {object} sourceData The object of all notification data
 * @param {string} propertyKey the propery key to get data for
 * @returns {null|object} notification object or null
 */
function getFirstItem(sourceData, propertyKey) {
    if (!sourceData[propertyKey]) {
        return null;
    }
    const notifications = sourceData[propertyKey].notifications;
    if (notifications && Array.isArray(notifications) && notifications.length > 0) {
        return notifications[0];
    } else {
        return null;
    }
}

/**
 * Get the correct endpoint parameters
 * @param {boolean} staff whether user is staff role
 * @param {boolean} student whether user is student role
 * @returns {string} notifications endpoint guid
 */
function calculateEndpoint(staff, student) {
    const intranet = NOTIFICATION_ENDPOINTS.intranetNotifications;
    const portal = NOTIFICATION_ENDPOINTS.portalNotifications;
    if (staff && student) {
        return `${intranet},${portal}`;
    }
    if (staff) {
        return intranet;
    }
    if (student) {
        return portal;
    }

    return null;
}