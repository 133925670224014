<template>
    <button
        v-if="authenticated && impersonating"
        id="refresh-user-login"
        aria-label="Refresh user login"
        class="org-header__refresh__button"
        style="cursor:pointer"
        @click="login"
    >
        <SyncAlt class="svg-inline--fa fa-w-16 org-header__altered-profile-icon" />
    </button>
    <a
        v-if="authenticated && mimicking"
        id="mimic-manager"
        aria-label="Manage mimic identities"
        href="/pas/identity/mimic"
        class="u-pad-r-05"
    >
        <GraduationCap class="svg-inline--fa fa-w-20 org-header__altered-profile-icon" />
    </a>
    <a
        v-if="authenticated && attached"
        id="attach-manager"
        aria-label="Manage attach identities"
        href="/pas/identity/attach"
        class="u-pad-r-05"
    >
        <Plus class="svg-inline--fa fa-w-20 org-header__altered-profile-icon" />
    </a>
    <a
        v-if="authenticated"
        id="user-options"
        href="javascript:void(0)"
        title="Open user options"
        role="button"
        :aria-expanded="expanded.toString()"
        aria-controls="user-menu"
        @click.prevent="handleClick"
    >
        <span
            id="user-name"
            class="org-header__profile-text"
            style="pointer-events: none;"
        >
            {{ fullName }}&nbsp;
        </span>
        <User
            title="Open user options"
            class="svg-inline--fa fa-w-14 org-header__user-profile-icon"
            pointer-events="none"
        />
        <ChevronDown
            title="Open user options"
            class="svg-inline--fa fa-w-14 org-header__arrow-icon"
            pointer-events="none"
        />
    </a>
</template>

<script>
import { computed } from 'vue';
import { useIdentityStore } from '@stores/identity.module';
import { getJwt } from '@core-portal/api/identity.api';
import { IconChevronDown, IconGraduationCap, IconPlus, IconSyncAlt, IconUser } from '@cisweb/icon-library-vue3';

export default {
    components: {
        ChevronDown: IconChevronDown,
        GraduationCap: IconGraduationCap,
        Plus: IconPlus,
        SyncAlt: IconSyncAlt,
        User: IconUser
    },
    props: {
        // state controlled by parent
        expanded: {
            type: Boolean,
            required: true
        }
    },

    emits: [
        'click'
    ],

    setup() {
        const identityStore = useIdentityStore();
        const profile = computed(() => identityStore.profile);
        const authenticated = computed(() => profile.value.isAuthenticated);

        return {
            profile,
            authenticated
        };
    },

    computed: {
        userMenuBtnEl() {
            return document.getElementById('user-options');
        },
        fullName() {
            return this.profile.fullname;
        },
        impersonating() {
            return this.profile.isImpersonating;
        },
        mimicking() {
            return this.profile.roles.some(r => r === 'mimic');
        },
        attached() {
            return this.profile.roles.some(r => r === 'attach');
        }

    },

    methods: {
        login() {
            getJwt();
        },
        /**
         * Emit value to parent
         */
        handleClick() {
            this.$emit('click');
        }
    }
};
</script>