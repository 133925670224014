import { getDspSections, getDspContent } from '@api/dsp';
import { REQUEST_STATUS } from '@config/constants';
import { getJwt } from '@core-portal/api/identity.api';
import { defineStore } from 'pinia';

export const useDspStore = defineStore('dsp', {
    state: () => ({
        structure: {
            children: []
        },
        currentSection: '',
        column: null,
        row: null,
        status: null,
        content: {
            sections: [],
            sectionsStatus: null,
            subSections: {},
            subSectionsStatus: null,
            content: {},
            contentStatus: null
        }
    
    }),

    persist: true,

    actions: {
        reset() {
            this.$reset();
        },
        setColumn(column) {
            this.column = column;
        },
        setRow(row) {
            this.row = row;
        },
        setActiveSection(currentSection) {
            this.currentSection = currentSection;
        },
        setSectionsRequest(status) {
            this.content.sectionsStatus = status;
        },
        setSections(sections) {
            this.content.sections = sections || [];
        },
        setSubSectionsRequest(status) {
            this.content.subSectionsStatus = status;
        },
        setSubSections({ data, key }) {
            this.content.subSections = { ...this.content.subSections, [key]: data };
        },
        setContentRequest(status) {
            this.content.contentStatus = status;
        },
        setContent({ data, key }) {
            this.content.content = { ...this.content.content, [key]: data };
        },
    
        async getDspSections({ section, subSection, clearCache }) {
            const isTopLevel = !section && !subSection;
            const requestType = isTopLevel ? 'setSectionsRequest' : 'setSubSectionsRequest';
            clearCache = clearCache ? true : false;
            try {
                this[requestType](REQUEST_STATUS.REQUEST);
                const data = await getDspSections(section, subSection, clearCache);
                this[requestType](REQUEST_STATUS.SUCCESS);
                if (isTopLevel) {
                    this.setSections(data);
                } else {
                    let key = !subSection ? section : `${section}/${subSection}`;
                    this.setSubSections({ data, key });
                }
            } catch (e) {
                if (e.code === 401) {
                    return getJwt();
                }
                this[requestType](REQUEST_STATUS.FAILURE);
            }
        },

        async getDspContent({ section, subSection, content }) {
            try {
                this.setContentRequest(REQUEST_STATUS.REQUEST);
                const data = await getDspContent(section, subSection, content);
                this.setContentRequest(REQUEST_STATUS.SUCCESS);

                const key = [section, subSection, content].join('/');
                this.setContent({ data, key });
            } catch (e) {
                if (e.code === 401) {
                    return getJwt();
                }
                this.setContentRequest(REQUEST_STATUS.FAILURE);
            }
        }
    },

    getters: {
        sections: (state) => state.content.sections || [],
        subSections: (state) => (key) => state.content.subSections[key] || null,
        getContentByKey: (state) => (key) => state.content.content[key] || null,
        sectionsRequestStatus: (state) => state.content.sectionsStatus,
        subSectionsRequestStatus: (state) => state.content.subSectionsStatus,
        contentRequestStatus: (state) => state.content.contentStatus,
        activeSection: (state) => (route) => {
            const { path } = route;
            return state.content.sections.find(x => path.indexOf(x.uri) > -1) || null;
        },
        activeSubSection: (state) => (route) => {
            const { path, params } = route;
            const subSections = state.subSections(params.parent) || [];
            return subSections.find(x => path.indexOf(x.uri) > -1 && x.uri.split('/').filter(x => x).length === 3) || null;
        },
        activeContent: (state) => (route) => {
            const { parent, section, content } = route.params;
            const key = [parent, section, content].join('/');
            return state.getContentByKey(key) || null;
        },
        breadcrumbs: (state) => (route) => {
            const root = {
                title: 'Services',
                uri: '/services/',
                link: {}
            };
            const activeSection = state.activeSection(route);
            const activeSubSection = state.activeSubSection(route);
            const activeContent = state.activeContent(route);

            let breadcrumbs = [root];

            if (activeSection) {
                breadcrumbs.push(activeSection);
            }
            if (activeSubSection) {
                breadcrumbs.push(activeSubSection);
            }
            if (activeContent) {
                breadcrumbs.push(activeContent);
            }

            return breadcrumbs;
        }
    },
});
