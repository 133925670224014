import { getSiteBaseUrl } from '@core-portal/scripts/siteBase.helper';

/**
 * Get/update the JWT token
 * @param {string} href url to return to when retrieving a token
 */
export function getJwt(href) {
    if (!href || typeof href !== 'string') {
        href = location.href;
    }
    const url = `${jwtProxy()}${encodeURIComponent(href)}`;
    window.location.assign(url);
}

/**
 * Get the url to use for retrieving a JWT
 * @returns {string} url to use for retrieving a JWT
 */
function jwtProxy() {
    let siteBaseUrl = getSiteBaseUrl();
    siteBaseUrl += siteBaseUrl.endsWith('/') ? '' : '/';
    return `${siteBaseUrl}login?returnUrl=`;
}