/**
 * Get the base uri of the app.
 * Falls back to "/" if not set.
 * @returns {string} app's base uri
 */
export function getBaseUri() {
    if (document.baseURI) {
        return document.baseURI;
    }

    const fallback = document.getElementsByTagName('base');
    if (fallback.length) {
        return fallback[0].href;
    }
    return '/';
}

/**
 * Get the site absolute Base Url 
 * @returns {string} (e.g. https://portal.lancaster.ac.uk/forms/module-enrol)
 */
export function getSiteBaseUrl() {
    let host = window.location.origin;
    let fullPath = '';

    // get the base URI of the site
    if (getBaseUri()) {
        fullPath += getBaseUri();
        // remove the domain name if it was picked up in the base uri
        fullPath = fullPath.replace(host, '');
    }

    return host + (fullPath.startsWith('/') ? fullPath : '/' + fullPath);
}