import Fetch from '@api/fetch';

/**
 * Get a number of most recent staff notices regardless of channel
 * @returns {Promise} Promise object represents api response
 */
export function getNoticeboardRecentItems() {
    return Fetch.internal.get('/api/noticeboard');
}

/**
 * Get details for a noticeboard item
 * @param {string} itemId the id of the item to be fetched
 * @returns {Promise} Promise object represents api response
 */
export function getNoticeboardItem(itemId) {
    return Fetch.internal.get(`/api/noticeboard/${itemId}`);
}

/**
 * Close a noticeboard item
 * @param {string} itemId the id of the item to be closed
 * @returns {Promise} Promise object represents api response
 */
export function closeNoticeboardItem(itemId) {
    return Fetch.internal.delete(`/api/noticeboard/${itemId}`);
}

/**
 * Get noticeboard channels
 * @returns {Promise} Promise object represents api response
 */
export function getNoticeboardChannels() {
    return Fetch.internal.get('/api/noticeboard/channels');
}