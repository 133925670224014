import { defineStore } from 'pinia';
import { getNoticeboardChannels } from '@api/noticeboard';
import { getIconComponent } from '@utilities/IconLibraryHelper';

export const useNoticeboardStore = defineStore('noticeboards', {
    state: () => ({
        channels: null
    }),
    persist: true,
   
    actions: {
        reset() {
            this.$reset();
        },
 
        async fetchChannels() {
            let data = [];
            // don't try to fetch the data if we've already got it
            let currentStoreValue = this.channels;
            if (currentStoreValue && currentStoreValue.length > 0) {
                data = currentStoreValue;
            } else {
                data = await getNoticeboardChannels();
            }          

            try {
                // add the icons based on the key
                data.forEach(channel => {
                    channel.icon = getIconComponent(channel.iconKey);
                });
                this.channels = data;
            } catch {
                this.channels = [];
            }
            
        },

    }
});
