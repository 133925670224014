<template>
    <!-- NOTE the tabIndex="0" and @keyup.enter="handleClick" is required in the <a> link, in this instance
         As otherwise the acessibility tabbing and 'enter' does not work for some reason.
         Usually tabIndex="0" and @keyup.enter="handleClick" would not be required, but have had to force it
    -->
    <a
        id="notifications-control"
        class="org-header__notifications org-grid-header-notifications org-header__notifications__button"
        role="button"
        aria-controls="notifications-area"
        aria-expanded="false"
        tabindex="0"
        @click="handleClick"
        @keyup.enter="handleClick"
    >
        <div class="org-header__notifications-text o-xs-screen-reader">
            Notifications
        </div>
        <div class="org-header__notifications-bell">
            <Bell
                id="notifications-bell"
                class="org-header__notifications-bell svg-inline--fa fa-w-14"
                :class="newClass"
            />
            <span
                v-if="count"
                class="org-header__notifications-circle-container"
            >
                <svg
                    id="notifications-count"
                    viewBox="0 0 512 512"
                    class="org-header__notifications-circle u-primary-red svg-inline--fa fa-w-16"
                >
                    <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                    />
                    <text
                        :x="nCount.x"
                        :y="nCount.y"
                    >
                        {{ nCount.count }}
                    </text>
                </svg>
            </span>
        </div>
    </a>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useTokenStore } from '@core-portal/stores/token.module';
import { useNotificationsStore } from '@stores/notifications.module';
import { IconBell } from '@cisweb/icon-library-vue3';

export default {
    components: {
        Bell: IconBell
    },
    setup() {

        // Interval to poll for new notifications
        const timerId = ref(null);
        const requestDelayMilliseconds = ref(5 * 1000); // 5 seconds
        const pollRefreshMilliseconds = ref(10 * 60 * 1000); // 10 minutes

        const notificationsStore = useNotificationsStore();

        // the notification count
        const count = computed(() => notificationsStore.getNotifications(notificationsStore.userEndpoint).length);

        /**
         * Method for polling for new notifications.
         * If the JWT has been cleared (logged out) then
         * clear the timeout and return
         */
        async function notificationsPoll() {
            const tokenStore = useTokenStore();
            const jwt = tokenStore.jwt;
            if (!jwt) {
                if (timerId.value) {
                    clearInterval(timerId.value);
                }
                return;
            }
            await notificationsStore.fetchUserNotifications();
        }

        /**
         * do the initial poll of notification data
         */
        async function initialPoll() {
            await notificationsPoll();
            await notificationsStore.fetchPriorityNotifications();
            await notificationsStore.fetchPromotionNotifications();
        }

        /**
         * handle click on the button
         */
        function handleClick() {
            notificationsStore.drawerSwitchState();
        }

        onMounted(async () => {
            // fetch the notifications when the component is first mounted
            setTimeout(initialPoll, requestDelayMilliseconds.value);

            // Poll for new notifications
            timerId.value = setInterval(notificationsPoll, pollRefreshMilliseconds.value);
        });

        return {
            count,
            handleClick
        };
    },
    computed: {
        newClass() {
            return {
                'org-header__notifications--new': this.count
            };
        },
        nCount() {
            return this.count > 9 ? { x: 90, y: 360, count: '9+' } : { x: 180, y: 360, count: this.count };
        }
    }
};
</script>