<template>
    <!-- notifications -->
    <section
        v-if="drawerOpen"
        id="notifications-area"
        aria-expanded="drawerOpen"
        class="org-notifications o-bg-menu-grey"
    >
        <div class="org-notifications__header o-bg-menu-grey o-bg-dark-grey--dark-mode">
            <div class="o-container o-container--notifications-header">
                <div class="org-notifications__header--title">
                    <h2>Notifications</h2>
                </div>
            </div>
        </div>

        <NotificationsRowGrid
            action-source="Drawer"
            class="o-container--notifications"
        />

        <div class="org-notifications__close o-bg-menu-grey o-bg-dark-grey--dark-mode">
            <div class="o-container u-align-center">
                <button
                    id="close-notifications-drawer"
                    type="button"
                    class="a-close-dialog-button a-close-dialog-button--small"
                    tabindex="0"
                    @click="closeDrawer"
                >
                    Close the Notifications Section
                    <span class="a-close-dialog-button__x">
                        X
                    </span>
                </button>
            </div>
        </div>
    </section>
</template>
<script>
import NotificationsRowGrid from '@molecules/rows/grids/NotificationsRowGrid.vue';

import { computed } from 'vue';
import { useNotificationsStore } from '@stores/notifications.module';

export default {
    name: 'NotificationDrawer',
    components: {
        NotificationsRowGrid
    },
    setup() {
        const notificationsStore = useNotificationsStore();
        const drawerOpen = computed(() => notificationsStore.drawerOpen);

        /**
         * Close the notification drawer
         */
        function closeDrawer() {
            notificationsStore.drawerSwitchState();
        }

        return {
            closeDrawer,
            drawerOpen
        };
    }
};
</script>