import { JOBS_TYPES, SECONDARY_NAV_ROUTE_KEYS } from '@config/constants';

const ArticleWrapper = () => import('@pages/ArticleWrapper.vue');
const AllJobs = () => import('@pages/jobs/AllJobs.vue');
const JobsRedirect = () => import('@pages/jobs/JobsRedirect.vue');
const StudentJobsPage = () => import('@pages/jobs/StudentJobsPage.vue');

export const JOBS_ROUTES = {
    HOME: 'JOBS_HOME',
    STUDENT: 'JOBS_STUDENT',
    STAFF:  'JOBS_STAFF',
    ALL_JOBS: 'JOBS_ALL_JOBS',
    TYPE: 'JOBS_TYPE'
};

export default [
    {
        path: '/jobs',
        component: JobsRedirect,
        name: JOBS_ROUTES.HOME,
        meta: {
            requiresAuth: true,
            title: 'Jobs',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS
        }
    },
    {
        path: '/jobs/staff',
        component: ArticleWrapper,
        name: JOBS_ROUTES.STAFF,
        // redirect to the HR jobs page
        redirect: `/jobs/type/${JOBS_TYPES.STAFF}`,
        meta: {
            requiresAuth: true,
            title: 'Staff Jobs',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS
        }
    },
    {
        path: '/jobs/student',
        component: StudentJobsPage,
        name: JOBS_ROUTES.STUDENT,
        meta: {
            requiresAuth: true,
            title: 'Student Jobs',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS
        }
    },
    {
        path: '/jobs/type',
        component: ArticleWrapper,
        name: JOBS_ROUTES.ALL_JOBS,
        // redirect to the jobs landing page if slug is not supplied
        redirect: '/jobs',
        children: [
            {
                path: ':slug',
                component: AllJobs,
                name: JOBS_ROUTES.TYPE,
                meta: {
                    requiresAuth: true,
                    title: 'All Jobs',
                    secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS
                }
            }
        ],
        meta: {
            requiresAuth: true,
            title: 'All Jobs',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS
        }
    }
];